import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { filter, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '@app/_api';
import { LayoutService } from '@app/_services';

import { LogoutDialogComponent } from './logout-dialog';

@Component({
  selector: 'sc-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit {
  private _currentRoutePath$: BehaviorSubject<any> = new BehaviorSubject(this.router.url);

  user$ = this.authService.user$;

  isLogged$ = this.authService.isLoggedIn$.pipe(
    map((isLoggedIn) => isLoggedIn.valueOf())
  );

  layoutConfig = this.layoutService.layoutConfig;

  currentRoutePath$ = this._currentRoutePath$.asObservable();

  isInAgreements$ = this.currentRoutePath$.pipe(
    map(path => path.includes('agreement'))
  );

  isUploadDocs$ = this.currentRoutePath$.pipe(
    map(path => path.includes('upload-documents'))
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private layoutService: LayoutService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event['url'])
    ).subscribe(this._currentRoutePath$);
  }

  onLogout() {
    if (!this.authService.hasDeviceHash()) {
      this.authService.logout();
      return;
    }

    this.matDialog.open<LogoutDialogComponent, void, void>(LogoutDialogComponent, {
      width: '698px',
      panelClass: ['cdk-transparent-panel', 'logout-panel'],
      backdropClass: 'cdk-overlay-ultra-dark',
      closeOnNavigation: true,
      autoFocus: true,
      disableClose: true,
    });
  }
}
