import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {environment} from '@env/environment';
import {TwoFaDeviceVerificationStepEnum} from '@app/my/settings/models/two-fa.model';
import {FormControl, Validators} from '@angular/forms';
import {AccountService, AuthService, FormDataService} from '@app/_api';
import {ToastyService} from '@app/_services';
import {take} from 'rxjs/operators';
import {ITwoFAAuthenticationInfo, User, VerificationOptionEnum} from '@app/models';
import { Router } from '@angular/router';

@Component({
  selector: 'sc-two-fa-device-verification-dialog',
  templateUrl: './two-fa-device-verification-dialog.component.html',
  styleUrls: ['./two-fa-device-verification-dialog.component.scss']
})
export class TwoFaDeviceVerificationDialogComponent implements OnInit {

  baseHref = environment.interiorUrl;
  twoFaDeviceVerificationEnum = TwoFaDeviceVerificationStepEnum;
  currentStep = TwoFaDeviceVerificationStepEnum.Loading;

  verificationCodeController = new FormControl('');
  verifyDeviceProcessing = false;
  verifyCodeProcessing = false;
  authenticationInfo: ITwoFAAuthenticationInfo;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {id: string, qrImageUrl: string, userAccount: User.Model, jwtToken: string, isLogin: boolean},
    public dialogRef: MatDialogRef<TwoFaDeviceVerificationDialogComponent>,
    private toastyService: ToastyService,
    private accountService: AccountService,
    private authService: AuthService,
    private formDataService: FormDataService,
    private router: Router
  ) { }

  ngOnInit() {
   const accountId = this.data.userAccount ? this.data.userAccount.id : this.authService.decoded.id;
   const jwtToken = this.data.jwtToken || null;

   this.accountService.get2faInfo(accountId, VerificationOptionEnum.App, jwtToken, this.data.isLogin).subscribe((data) => {
      this.authenticationInfo = data;
      this.currentStep = TwoFaDeviceVerificationStepEnum.QR;
    });
    this.formDataService.formData
      .pipe(take(1))
      .subscribe(data => {
        this.verificationCodeController.setValidators([
          Validators.required,
          Validators.pattern(data.account.device.valid_pattern.app)
        ]);
      });
  }

  onNext() {
    this.currentStep = TwoFaDeviceVerificationStepEnum.VerificationCode;
  }

  onVerifyCode() {
    if (this.verificationCodeController.valid && !this.verifyCodeProcessing) {
      this.verifyCodeProcessing = true;
      const id = this.authService.decoded ? this.authService.decoded.id : this.data.userAccount.id;

      this.accountService.checkDeviceVerificationCode(
        {
          jwt: this.data.jwtToken,
          accountId: id,
          verificationCode: this.verificationCodeController.value.trim(),
          tfaType: VerificationOptionEnum.App,
          secret: this.authenticationInfo.id,
        }
      )
      // this.accountService.check2faVerificationCode(
      //   id,
      //   this.verificationCodeController.value.trim(),
      //   VerificationOptionEnum.App,
      //   this.authenticationInfo.id
      // )
        .subscribe(({isValid}) => {
          this.verifyCodeProcessing = false;
          if (isValid) {
            this.currentStep = TwoFaDeviceVerificationStepEnum.AssociateDevice;
          } else {
            this.verificationCodeController.setErrors({
              wrongSecurityCode: true
            });
          }
        }, () => {
          this.verifyCodeProcessing = false;
          this.toastyService.error('Something went wrong.');
        });
    }
  }

  onAssociateDevice() {
    if (this.verifyDeviceProcessing) {
      return;
    }

    this.verifyDeviceProcessing = true;
    const id = this.authService.decoded ? this.authService.decoded.id : this.data.userAccount.id;
    this.accountService.updateDeviceStatusToVerified(id, this.data.isLogin, this.data.jwtToken)
      .subscribe(
        () => {
          if (this.data.isLogin) {
            this.authService.logIn(this.data.userAccount, this.data.jwtToken);
            this.router.navigate(['/my']).then(() => {
              this.dialogRef.close(true);
            });

          } else {
            this.dialogRef.close(true);
          }

        },
        (error) => {
          this.verifyDeviceProcessing = false;
          this.toastyService.warning('Something went wrong!', null, {
            verticalPosition: 'top',
          });
        }
      );
  }

  onCopy() {
    const textarea: HTMLTextAreaElement = document.createElement('textarea');
    textarea.value = this.authenticationInfo.id;
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.opacity = '0';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.toastyService.success('ID has been successfully copied to clipboard', '', {
      duration: 2000
    });
  }

  onBackOrCancel() {
    if (this.data.isLogin) {
      this.currentStep = TwoFaDeviceVerificationStepEnum.QR;
      this.verificationCodeController.reset();
    } else {
      this.dialogRef.close();
    }
  }
}
