import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  private readonly baseUrl: string;
  private config: Observable<any>;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseUrl = `${environment.apiUrl}/discount`;
  }

  check(
    data: {
      account_id: number,
      type: 'premium ad' | 'premium service',
      profile_id?: string,
      premium_service_name?: string,
      premium_service_principle_category?: string,
      premium_service_secondary_category?: string
    }
  ) {
    const endpoint = `${this.baseUrl}/_check`;

    return this.httpClient.post(endpoint, data).pipe(
      map((json: any) => ({
        accountId: json.account_id,
        profileId: json.profile_id,
        type: json.type,
        discountId: json.discount_id,
        percentage: json.percentage
      }))
    );
  }

  getConfig(): Observable<any> {
    if (this.config) {
      return this.config;
    }

    const endpoint = `${this.baseUrl}/configs`;

    return (
      this.config = this.httpClient.get<any>(endpoint).pipe(shareReplay(1))
    );
  }
}
