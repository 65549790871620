import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minTwoDigits'
})
export class MinTwoDigitsPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return (parseInt(value) < 10 ? '0' : '') + parseInt(value);
  }

}
