class MetaRevision {
  createdBy: number;
  dateCreated: Date;
  dateModified: Date;
  isDeleted: boolean;
  modifiedBy: number;
  number: number;
  status: string;
  isIncomplete: boolean;
  partialProcess: any[];
  declineReasons: string[];
}

export function fromMetaJson(json): MetaRevision {
  return {
    createdBy: json.created_by,
    dateCreated: json.date_created,
    dateModified: json.date_modified,
    isDeleted: json.is_deleted,
    modifiedBy: json.modified_by,
    number: json.number,
    status: json.status,
    isIncomplete: json.is_incomplete,
    partialProcess: json.partial_process,
    declineReasons: json.decline_reasons || []
  };
}

export namespace Revision {
  export class Model {
    profileId: string;
    title: string;
    text: string;
    categories?: string[];
    category?: string;
    // availability: RevisionAvailability.Model | null;
    services: string[];
    provinces: string[];
    prices: Array<{
      duration: number;
      unit: string;
      value: number;
    }> | null;
    escortPrices: Array<{
      duration: number;
      unit: string;
      value: number;
    }> | null;
    phoneCountryCode: string;
    phoneNumber: string;
    gender: string;
    sexOrientation: string;
    name: string;
    birthDate: string;
    countryCode: 'nl' | 'be';
    postalCode: string;
    city: string;
    ethnicity: string;
    bodyType: string;
    hairColor: string;
    height: number;
    cupSize: string;
    penisLength: number;
    circumcised: string;
    languages: string[];
    photos: Array<{ id: string }>;
    videos: Array<{ id: string }>;
    useCompanyAddress?: boolean;
    url: string;
    revision?: MetaRevision;
    workingHours: null;
  }

  export function fromJson(json: any): Model {
    const revModel = {
      profileId: json.profile_id,
      title: json.title,
      text: json.text,
      category: json.category,
      categories: null,
      // json.categories &&
      // json.categories.map(item => {
      //   if (item === 'mistress') item = 'bdsm';
      //   return item;
      // }),
      // availability: json.availability,
      services: json.services,
      provinces: json.provinces,
      prices: json.prices,
      escortPrices: json.escort_prices,
      phoneCountryCode: json.phone_country_code,
      phoneNumber: json.phone_number,
      gender: json.gender,
      sexOrientation: json.sex_orientation,
      name: json.name,
      birthDate: json.birth_date,
      countryCode: json.country_code,
      postalCode: json.postal_code,
      city: json.city,
      ethnicity: json.ethnicity,
      bodyType: json.body_type,
      hairColor: json.hair_color,
      height: json.height,
      cupSize: json.cup_size,
      penisLength: json.penis_length,
      circumcised: json.circumcised,
      languages:
        json.languages && json.languages.filter(item => item !== 'other'),
      photos: json.photos,
      videos: json.videos,
      url: json.url,
      revision: fromMetaJson(json.revision),
      workingHours: json.working_hours
    };

    if (json.hasOwnProperty('use_company_address')) {
      revModel['useCompanyAddress'] = json['use_company_address'];
    }

    if (json.hasOwnProperty('revision')) {
      revModel['revision'] = fromMetaJson(json.revision);
    }

    return revModel;
  }

  export function toJson(model: Partial<Model>): any {
    const revModel = {
      profile_id: model.profileId,
      title: model.title,
      text: model.text,
      category: model.category,
      categories: model.categories,
      // availability: model.availability,
      services: model.services,
      provinces: model.provinces,
      prices: model.prices,
      escort_prices: model.escortPrices,
      phone_country_code: model.phoneCountryCode,
      phone_number: model.phoneNumber,
      gender: model.gender,
      sex_orientation: model.sexOrientation,
      name: model.name,
      birth_date: model.birthDate,
      country_code: model.countryCode,
      postal_code: model.postalCode,
      city: model.city,
      ethnicity: model.ethnicity,
      body_type: model.bodyType,
      hair_color: model.hairColor,
      height: model.height,
      cup_size: model.cupSize,
      penis_length: model.penisLength,
      languages: model.languages,
      photos: model.photos,
      videos: model.videos,
      working_hours: model.workingHours,
      ...(model.hasOwnProperty('url') && { url: model.url ? model.url : null }),
      ...(model.hasOwnProperty('circumcised') && {
        circumcised: model.circumcised
      })
    };

    if (model.hasOwnProperty('useCompanyAddress')) {
      revModel['use_company_address'] = model.useCompanyAddress;
    }

    return revModel;
  }

  export function empty(profileId?: string): Model {
    return {
      profileId,
      title: '',
      text: '',
      category: null,
      categories: null,
      // availability: RevisionAvailability.empty(),
      services: null,
      provinces: null,
      prices: [],
      escortPrices: null,
      phoneCountryCode: '+31',
      phoneNumber: null,
      gender: null,
      sexOrientation: null,
      name: '',
      birthDate: null,
      countryCode: 'nl',
      postalCode: '',
      city: '',
      ethnicity: '',
      bodyType: '',
      hairColor: '',
      height: null,
      cupSize: null,
      penisLength: null,
      circumcised: '',
      languages: null,
      photos: [],
      videos: null,
      useCompanyAddress: true,
      url: null,
      workingHours: null
    };
  }

  export function clone(model: Model): Model & { [key: string]: any } {
    return JSON.parse(JSON.stringify(model));
  }
}
