import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@app/_api/auth.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Message } from '@app/models/message.model';

@Injectable({ providedIn: 'root' })
export class MessagesService {
  constructor(private http: HttpClient) {}
  messagesCount$ = new BehaviorSubject<number>(0);

  getMessagesByAccountId(accountId: number): Observable<Message.Model[]> {
    const url = `${environment.apiUrl}/notification/messages`;
    const params = new HttpParams().set('account_id', accountId.toString());

    return this.http
      .get(url, { params })
      .pipe(map((res: any[]) => res.map(item => Message.fromJson(item))));
  }

  getMessageByMessageId(messageId: string) {
    const url = `${environment.apiUrl}/notification/message/${messageId}`;
    return this.http.get(url);
  }

  markAsRead(messageId: string) {
    const url = `${environment.apiUrl}/notification/message/${messageId}`;
    const params = new HttpParams().set('status', 'read');

    return this.http.put(url, params);
  }

  deleteMessage(messageId) {
    const url = `${environment.apiUrl}/notification/message/${messageId}`;

    return this.http.delete(url);
  }

  getMessagesCount(accountId: number) {
    const url = `${environment.apiUrl}/notification/messages/count`;
    const params = new HttpParams().set('account_id', accountId.toString());

    return this.http
      .get(url, { params })
      .pipe(tap((res: number) => this.messagesCount$.next(res)));
  }
}
