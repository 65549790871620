import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { EMPTY } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { AccountService, AuthService } from '@app/_api';

@Component({
  selector: 'sc-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoutDialogComponent implements OnInit {

  control = new FormControl(false);

  processing = false;

  constructor(
    private dialogRef: MatDialogRef<LogoutDialogComponent, void>,
    private accountService: AccountService,
    private authService: AuthService
  ) { }

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.processing = true;

    const logoutFormAllDevices = this.control.value;

    if (!logoutFormAllDevices) {
      this.processing = false;
      this.dialogRef.close();
      this.authService.logout();

      return;
    }

    this.authService.user$.pipe(
      take(1),
      switchMap((authAccount) =>
        this.accountService.logout(authAccount.id, logoutFormAllDevices).pipe(
          tap(() => {
            this.processing = false;
            this.dialogRef.close();
            this.authService.logout(true);
          }),
          catchError((error) => {
            this.processing = false;
            return EMPTY;
          })
        )
      )
    )
      .subscribe();
  }
}
