import { Image } from './image.model';
import { Person } from '@app/models/person.model';
import { StoryViewModel } from '@app/my/stories/models/story.model';
import { EXCLUDED_MAIN_CATEGORIES, EXCLUDED_SPECIALTY_CATEGORIES } from '@app/models/configs.model';

export namespace Profile {
  export interface Model {
    id: string;
    profileId?: string;
    isMulti: boolean;
    isFast: boolean;
    personId: string;
    personIds: string[];
    accountId: number;
    isInventoryLocked: boolean;
    isAuthenticated: boolean;
    showname: string;
    tagline: string;
    age: number;
    gender: string;
    phoneNumbers: Array<ProfilePhoneNumber.Model>;
    emails: string[];
    websites: string[];
    locationId: number;
    mainCategories: string[];
    transOperation: string | null;
    visitingText: string | null;
    isVisiting: boolean;
    isVip: boolean;
    inventoryId: number;
    isAnimated: boolean;
    bioText: string | null;
    offersList: string | null;
    donations: string | null;
    disclaimer: string | null;
    hairColor: string;
    eyeColor: string;
    ethnicities: string[];
    unit: string;
    height: number;
    weight: number;
    bustSize: number;
    waistSize: number;
    hipSize: number;
    cupSize: string;
    affiliation: string;
    availableTo: string[];
    specialtyCategories: string[];
    locationCategories: string[];
    locationTags: string;
    availableFor: Array<ProfileAvailableFor.Model>;
    social: Array<ProfileSocial.Model>;
    dateCreated?: Date;
    dateModified?: Date;
    erosId?: number;
    revision?: Model;
    revisionMeta?: ProfileRevision.Model;
    photos: Array<Partial<ProfilePhoto.Model>>;
    mainPhoto?: ProfilePhoto.Model;
    style: string;
    referenceId: number;
    isAvailableNow: boolean;
    isOnline?: boolean;
    magazineReference?: string | null;
    movieReference?: string;
    alternativeSpecialty: string[];
    isBundledCategory?: boolean;
    isOnlyBannedCategory?: boolean;

    profile?: any;
    people?: Person.Model[];
    locationFormatted?: string;
    locationName?: string;
    pseudo_status?: string;
    date_activation_converted?: string;
    date_expiration_converted?: string;
    date_activation?: string;
    date_expiration?: string;
    date_expiration_time?: string;
    hasStories?: boolean;
    status?: string;
    location?: {
      timezone: string,
      path: string
    };
    main_photo?: ProfilePhoto.Model;
    indexed_text?: any;
    stories?: StoryViewModel[];

    revVip: string;
  }

  export function fromJson(json: any): Model {
    return {
      id: json._id,
      isMulti: json.is_multi,
      isFast: json.is_fast,
      profileId: json.profile_id,
      personId: json.person_id,
      personIds: json.person_ids || [],
      accountId: json.account_id,
      isInventoryLocked: json.is_inventory_locked,
      isAuthenticated: json.is_authenticated,
      showname: json.showname,
      tagline: json.tagline,
      age: json.age,
      gender: json.gender,
      phoneNumbers: json.phone_numbers
        ? json.phone_numbers.map(num => ProfilePhoneNumber.fromJson(num))
        : [],
      emails: json.emails ? json.emails : [],
      websites: json.websites ? json.websites : [],
      locationId: json.location_id,
      transOperation: json.trans_operation,
      visitingText: json.visiting_text,
      isVisiting: json.is_visiting,
      isVip: json.is_vip,
      inventoryId: json.inventory_id,
      isAnimated: json.is_animated,
      bioText: json.isFast ? null : json.bio_text, /*StaticFix*/
      offersList: json.offers_list ? json.offers_list : '',
      donations: json.donations ? json.donations : '',
      disclaimer: json.isFast ? null : json.disclaimer, /*StaticFix*/
      hairColor: json.hair_color,
      eyeColor: json.eye_color,
      ethnicities: json.ethnicities ? json.ethnicities : [],
      unit: json.unit,
      height: json.height,
      weight: json.weight,
      bustSize: json.bust_size,
      waistSize: json.waist_size,
      hipSize: json.hip_size,
      cupSize: json.cup_size,
      affiliation: json.affiliation,
      availableTo: json.available_to ? json.available_to : [],
      mainCategories: json.main_categories ? json.main_categories.filter(cat => !EXCLUDED_MAIN_CATEGORIES.includes(cat)) : [], /*StaticFix*/
      specialtyCategories: json.specialty_categories ? json.specialty_categories.filter(cat => !EXCLUDED_SPECIALTY_CATEGORIES.includes(cat)) /*StaticFix*/ : [],
      alternativeSpecialty: json.alternative_specialty ? json.alternative_specialty : [],
      locationCategories: json.location_categories ? json.location_categories : [],
      isBundledCategory: json.main_categories ?
        json.main_categories.some(cat => EXCLUDED_MAIN_CATEGORIES.includes(cat)) && !json.main_categories.every(cat => EXCLUDED_MAIN_CATEGORIES.includes(cat)) : false,
      isOnlyBannedCategory: json.main_categories ? json.main_categories.every(cat => EXCLUDED_MAIN_CATEGORIES.includes(cat)) : false,
      locationTags: null, /*StaticFix*/ // json.location_tags,
      availableFor: json.available_for
        ? json.available_for.map(afor => ProfileAvailableFor.fromJson(afor))
        : [],
      social: json.social
        ? ProfileSocial.fromJson(json.social)
        : ProfileSocial.empty(),
      erosId: json.eros_id,
      isAvailableNow: json.is_available_now,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      revisionMeta: json.revision
        ? ProfileRevision.fromJson(json.revision)
        : null,
      photos: json.photos
        ? json.photos.map(photo => ProfilePhoto.fromJson(photo))
        : [],
      style: json.style || 'default',
      referenceId: json.reference_id,
      magazineReference: json.magazine_reference,
      movieReference: json.movie_reference,
      mainPhoto: json.photos ? json.photos[0] : null,
      stories: [],
      revVip: json.rev_vip
    };
  }

  export function empty(): Profile.Model {
    return {
      id: null,
      isFast: false,
      emails: [],
      phoneNumbers: [],
      tagline: '',
      gender: '',
      age: null,
      showname: '',
      revision: null,
      isOnline: false,
      accountId: null,
      affiliation: '',
      alternativeSpecialty: [],
      availableFor: [],
      availableTo: [],
      bioText: '',
      offersList: '',
      donations: '',
      bustSize: null,
      cupSize: null,
      dateCreated: null,
      dateModified: null,
      disclaimer: undefined,
      erosId: null,
      ethnicities: [],
      eyeColor: null,
      hairColor: null,
      height: null,
      hipSize: null,
      inventoryId: null,
      isAnimated: false,
      isAuthenticated: false,
      isAvailableNow: false,
      isInventoryLocked: false,
      isMulti: false,
      isVip: false,
      isVisiting: false,
      locationCategories: [],
      locationId: null,
      locationTags: null,
      magazineReference: undefined,
      mainCategories: [],
      mainPhoto: null,
      movieReference: undefined,
      personId: null,
      personIds: [],
      photos: [],
      style: '',
      profileId: null,
      referenceId: null,
      revisionMeta: undefined,
      social: ProfileSocial.empty(),
      specialtyCategories: [],
      transOperation: null,
      unit: null,
      waistSize: null,
      websites: [],
      weight: null,
      visitingText: null,
      stories: [],
      revVip: null
    };
  }

  export function toJson(profile: Profile.Model) {
    return  {
      profile_id: profile.profileId,
      showname: profile.showname,
      tagline: profile.tagline,
      age: profile.age,
      style: profile.style || 'default',
      gender: profile.gender,
      phone_numbers: (() => {
        if (profile.phoneNumbers.length === 0) return null;
        return profile.phoneNumbers.map(({number, hasViber, hasWhatsapp, hasTextOnly}) => ({
          number,
          has_viber: hasViber,
          has_whatsapp: hasWhatsapp,
          has_text_only: hasTextOnly,
        }));
      })(),
      emails: profile.emails.length === 0 ? null : profile.emails,
      websites: null,
      location_id: profile.locationId,
      main_categories: profile.mainCategories,
      photos: profile.photos,
      trans_operation: profile.transOperation || null,
      visiting_text: profile.visitingText || null,
      is_visiting: profile.isVisiting,
      is_vip: profile.isVip,
      inventory_id: profile.inventoryId,
      is_animated: profile.isAnimated,
      bio_text: profile.bioText || null,
      offers_list: profile.offersList || null,
      donations: profile.donations || null,
      // disclaimer: profile.disclaimer || null,
      hair_color: profile.hairColor || null,
      eye_color: profile.eyeColor || null,
      ethnicities: profile.ethnicities.length === 0 ? null : profile.ethnicities,
      unit: profile.unit,
      height : profile.height || null,
      weight : profile.weight || null,
      bust_size : profile.bustSize || null,
      waist_size : profile.waistSize || null,
      hip_size : profile.hipSize || null,
      cup_size : profile.cupSize || null,
      affiliation : profile.affiliation || null,
      available_to: profile.availableTo.length === 0 ? null : profile.availableTo,
      specialty_categories: profile.specialtyCategories.length === 0 ? null : profile.specialtyCategories,
      alternative_specialty: profile.alternativeSpecialty.length === 0 ? null : profile.alternativeSpecialty,
      location_categories: profile.locationCategories.length === 0 ? null : profile.locationCategories,
      location_tags: null, // Todo what is this
      available_for: profile.availableFor.length === 0 ? null : profile.availableFor,
      social: profile.social,
      rev_vip: profile.revVip
    };
  }
}

export namespace ProfileRevision {
  export enum Status {
    PENDING = 'pending',
    APPROVED = 'approved',
    DECLINED = 'declined',
  }

  export interface Model {
    createdBy: number;
    status: Status;
    number: number;
    processedBy: number;
    modifiedBy: number;
    isModifiable: boolean;
    isDeleted: boolean;
    isApprovable: boolean;
    dateCreated: Date;
    dateModified: Date;
  }

  export function fromJson(json: any): Model {
    return {
      status: json.status,
      number: json.number,
      createdBy: json.created_by,
      processedBy: json.processed_by,
      modifiedBy: json.modified_by,
      isModifiable: json.is_modifiable,
      isApprovable: json.is_approvable,
      isDeleted: json.is_deleted,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      dateModified: json.date_modified ? new Date(json.date_modified) : null
    };
  }
}

export namespace ProfilePhoneNumber {
  export interface Model {
    number: string;
    hasViber: boolean;
    hasWhatsapp: boolean;
    hasTextOnly: boolean;
  }

  export function fromJson(json: any): Model {
    return {
      number: json.number,
      hasViber: Boolean(json.has_viber),
      hasWhatsapp: Boolean(json.has_whatsapp),
      hasTextOnly: Boolean(json.has_text_only)
    };
  }
}

export namespace ProfileAvailableFor {
  export interface Model {
    type: string;
    address: string;
    latitude: number;
    longitude: number;
    radius: number;
  }

  export function fromJson(json: any) {
    return {
      type: json.type,
      address: json.address,
      latitude: json.latitude,
      longitude: json.longitude,
      radius: json.radius
    };
  }

  export function isEqual(comp1: Model, comp2: Model): boolean {
    return (
      comp1.type === comp2.type &&
      comp1.address === comp2.address &&
      comp1.latitude === comp2.latitude &&
      comp1.longitude === comp2.longitude &&
      comp1.radius === comp2.radius
    );
  }
}

export namespace ProfileSocial {
  export interface Model {
    type: string;
    handle: string;
  }

  export function fromJson(
    json: Array<{ type: string, handle: string | null }>
  ): Model[] {
    return ProfileSocial.empty()
      .map((item) => {
        const profileSocialLink = json.find((_item) => _item.type === item.type);
        return profileSocialLink ? profileSocialLink : item;
      });
  }

  export function empty() {
    return [
      {type: 'youtube', handle: null},
      {type: 'facebook', handle: null},
      {type: 'twitter', handle: null},
      {type: 'google plus', handle: null},
      {type: 'snapchat', handle: null},
      {type: 'vine', handle: null},
      {type: 'tumblr', handle: null},
      {type: 'periscope', handle: null},
      {type: 'instagram', handle: null},
      {type: 'tiktok', handle: null},
    ];
  }

  export function isEqual(comp1: Model, comp2: Model): boolean {
    return comp1.type === comp2.type && comp1.handle === comp2.handle;
  }

  export function isEmpty(model: Model): boolean {
    return !model.handle;
  }
}

export namespace ProfilePhoto {
  export interface Model {
    id: string;
    imageId: string;
    status: string;
    number: number;
    personId: string;
    processedBy?: number;
    dateProcessed?: Date;
    dateCreated: Date;
    isMain?: boolean;
    isNew?: boolean;
    media?: Image.Model;

    cId?: string;
    processing?: boolean;
    loaded?: boolean;
    error?: Error;
    person?: Person.Model;
  }

  export function fromJson(json: any): Model {
    return {
      id: json._id || json.id,
      imageId: json.image_id,
      personId: json.person_id,
      processedBy: json.processed_by,
      dateProcessed: json.date_processed ? new Date(json.date_processed) : null,
      dateCreated: new Date(json.date_created),
      isMain: json.is_main,
      isNew: json.is_new,
      status: json.status,
      number: json.number,
      media: null
    };
  }

  export function empty(): Partial<Model> {
    return {};
  }
}

export namespace ProfileStyle {
  export interface Model {
    id: string;
    name: string;
    ordering: number;
    isVisible: boolean;
    blockColor: string;
    class: {[key: string]: string};
  }

  export function fromJson(json: any): Model {
    return {
      id: json._id || json.id,
      ordering: json.ordering,
      name: json.name,
      isVisible: json.is_visible,
      blockColor: `#${json.block_color}`,
      class: json.class
    };
  }
}

// export namespace ProfilePhotoMedia {
//   export interface Model {
//     id: string;
//     path: string;
//     width: number;
//     height: number;
//     isPortrait: boolean;
//     size: number;
//     status: string;
//     hash: string;
//     ext: string;
//     thumbName: string;
//     filename: string;
//     type: string;
//     dateCreated: Date;
//   }
//
//   export function fromJson(json: any): Model {
//     return {
//       id: json.image_id,
//       path: json.path,
//       width: json.width,
//       height: json.height,
//       isPortrait: json.is_portrait,
//       size: json.size,
//       status: json.status,
//       hash: json.hash,
//       ext: json.ext,
//       thumbName: json.thumb_name,
//       filename: json.filename,
//       type: json.type,
//       dateCreated: new Date(json.date_created)
//     };
//   }
//
//   export function formatUrl(): string {
//     // return this.contentServerUrl + '/' + this.path;
//     return '';
//   }
// }
