import { Inject, Injectable, NgZone } from '@angular/core';
import { ScriptLoaderService } from '@app/_services/script-loader.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, mapTo } from 'rxjs/operators';

export interface BlackBox {
  bb: string;
  complete: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IovationService {

  private _blackBoxSuccess = new ReplaySubject<BlackBox>(1);

  constructor(private scriptLoaderService: ScriptLoaderService,
              private _zone: NgZone) {
    window['io_global_object_name'] = 'IGLOO';
    window['IGLOO'] = window['IGLOO'] || {
      enable_flash : false,
      bbout_element_id : 'ioBB',
      loader : {
        uri_hook : 'iojs',
        version : 'general5',
        // tp_host: 'https://ci-first.iovation.com/',
        subkey: 'POpe4svblNcRdMW-9801mVnBLBhzcgC2LigvTG2E5p0',
      }

    };

    this.scriptLoaderService.load({
      name: 'Iovation',
      type: 'src',
      src: `/assets/js/iovation.js`
    }).subscribe(() => this._getBlackBox());
  }

  private _getBlackBox() {
    let counter = 0;
    const _interval = setInterval(() => {
      counter ++;
      const res = window['IGLOO'].getBlackbox();
      const { blackbox, finished } = res;

      if (finished) {
        this._zone.runTask(() => {
          this._blackBoxSuccess.next({ bb: blackbox, complete: finished });
          this._blackBoxSuccess.complete();
        });
        clearInterval(_interval);
      } else if (counter === 5) {
        clearInterval(_interval);
      }
    }, 3000);
  }

  getBlackBox(): Observable<BlackBox> {
    return this._blackBoxSuccess.asObservable();
  }
}
