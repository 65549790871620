import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs/operators';
import { WINDOW } from '@app/_shared/tokens';

type ScrollTargetKlass = {
  klassName: string,
  wrapperPadding: number
};

export const SCROLL_TARGET_TOKEN = new InjectionToken('SCROLL_TARGET', {
  providedIn: 'root',
  factory: () => {
    return {
      klassName: 'mat-form-field.ng-invalid',
      wrapperPadding: 14
    };
  }
});


/**
 * @desc please provide me in component decorator provider, for better user experience :))
 * */
@Injectable(/*{providedIn: 'root'}*/)
export class ScrollDispatcher implements OnDestroy {
  private readonly dispatcher = new Subject<Partial<ScrollTargetKlass>>();
  private readonly dispatcher$ = this.dispatcher.asObservable();
  private subs = new Subscription();

  private wrapperPadding = 14;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(SCROLL_TARGET_TOKEN) private defaultScrollTarget: ScrollTargetKlass
  ) {
    this.subs.add(
      this.dispatcher$
        .pipe(
          tap(({klassName, wrapperPadding}) => {
            const firstInvalid = (document.querySelector(klassName) as HTMLElement);
            const padding = typeof wrapperPadding === 'number'
              ? wrapperPadding
              : this.defaultScrollTarget.wrapperPadding;

            if (firstInvalid) {
              this.window.scrollTo({
                top: firstInvalid.offsetTop - padding,
                behavior: 'smooth',
              });
            }
          })
        )
        .subscribe()
    );
  }

  tryScrollToFirstInvalid(opts?: ScrollTargetKlass) {
    this.dispatcher.next(opts ? opts : this.defaultScrollTarget);
  }

  scrollTo(posY: number) {
    this.window.scrollTo({
      top: posY,
      behavior: 'smooth',
    });
  }

  scrollToTop() {
    this.window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  scrollToBottom() {
    this.window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
