import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { FormDataService } from '@app/_api';

@Injectable({
  providedIn: 'root'
})
export class ConfigsGuard implements CanActivate {
  constructor(
    private router: Router,
    private formDataService: FormDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.formDataService.formData.pipe(
      filter(res => !!res),
      take(1),
      map(() => true),
    );
  }
}
