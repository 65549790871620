export namespace Message {
  export class Model {
    accountId: number;
    body: string;
    createdBy: number;
    dateCreated: Date;
    isDeleted: boolean;
    status: string;
    subject: unknown;
    type: string;
    id: string;
  }

  export function fromJson(json: any): Model {
    return {
      accountId: json.account_id,
      body: json.body,
      createdBy: json.created_by,
      dateCreated: new Date(json.date_created),
      isDeleted: json.is_deleted,
      status: json.status,
      subject: json.subject,
      type: json.type,
      id: json._id
    };
  }
}
