import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { AuthInterceptor } from './auth-interceptor';
import { RequestCache, RequestCacheWithMap } from './request-cache.service';
import { CachingInterceptor } from './caching-interceptor';

export const httpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  // { provide: RequestCache, useClass: RequestCacheWithMap },
  // { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true }
];
