import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ConfirmDialogComponent } from './confirm-dialog.component';

export interface Options  {
  type: 'delete' | 'info' | 'warning' | 'create' | 'update' | 'feedback';
  title: string;
  text: string;
  width?: string;
  textClass?: string;
  stayAfterSubmit?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  hasBackdrop?: boolean;
  templateRef?: TemplateRef<any>;
  nativeClass?: string;
  panelClass?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) {

  }

  open(options: Options) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: options,
      panelClass: options.panelClass ? ['cdk-transparent-panel', options.panelClass] : 'cdk-transparent-panel',
      backdropClass: 'cdk-overlay-ultra-dark',
      width: options.width || '400px',
      hasBackdrop: options.hasBackdrop || true
    });

    return {
      dialogRef,
      onClose: dialogRef.afterClosed(),
      onSubmit: dialogRef.componentInstance.onSubmit
    };
  }

  closeAll() {
    this.dialog.closeAll();
  }

}

