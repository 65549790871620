import { Injectable } from '@angular/core';
import {
  FormControl,
  ValidationErrors,
  FormGroup,
  AsyncValidator,
  AbstractControl,
  AsyncValidatorFn
} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { AccountService } from '@app/_api';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() {}

  static email = (config, req = false) => (
    control: FormControl
  ): ValidationErrors | null => {
    const emailC = config.email;
    const pattern = emailC.valid_pattern;

    const emailPattern = new RegExp(pattern, 'i');

    if (req && !control.value) {
      return {
        error: `Email is required.`
      };
    }

    if (!control.value.match(emailPattern)) {
      return {
        error: `Invalid email address. Example john@doe.com.`
      };
    }

    return null;
  };

  static password = (config, req = true) => (
    control: FormControl
  ): ValidationErrors | null => {
    const pass = config.password;
    const pattern = pass.valid_pattern;

    if (req && !control.value) {
      return {
        error: `Password required!`
      };
    }

    if (control.value && control.value.length > pass.max) {
      return {
        error: `Maximum ${pass.max} symbols are allowed`
      };
    }

    if (control.value && control.value.length < pass.min) {
      return {
        error: `Should contain at least ${pass.min} symbols`
      };
    }

    if (control.value && !control.value.match(pattern)) {
      return {
        error: 'Invalid Password'
      };
    }

    return null;
  };

  static areEqual = (group: FormGroup) => {
    const { password, confirmPassword } = group.value;

    if (password !== confirmPassword) {
      group.get('confirmPassword').setErrors({ error: 'Passwords mismatch!' });
    } else if (password === confirmPassword) {
      group.get('confirmPassword').setErrors(null);
    }

    return null;
  };

  static areEqualForPass = (group: FormGroup) => {
    const { password, confirmPassword } = group.value;

    if (password !== confirmPassword) {
      group.get('confirmPassword').setErrors({ error: 'Passwords mismatch!' });
    } else if (password === confirmPassword) {
      group.get('confirmPassword').setErrors(null);
    }

    return null;
  };

  static areEqualSecurityAnswers = (group: FormGroup) => {
    const passValue = group.get('securityAnswer').value;
    const cpassValue = group.get('retypeAnswer').value;

    if (passValue !== cpassValue) {
      group.get('retypeAnswer').setErrors({ error: 'Answers missmatch!' });
    }

    if (passValue === cpassValue) {
      group.get('retypeAnswer').setErrors(null);
    }

    return null;
  };

  static accountName = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const acName = config.name;
    const pattern = acName.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    const namePattern = new RegExp(pattern, 'i');

    // if (control.value && control.value.length > acName.max) {
    //   return {
    //     error: `Maximum ${acName.max} symbols are allowed`
    //   };
    // }
    //
    // if (control.value && control.value.length < acName.min) {
    //   return {
    //     error: `Should contain at least ${acName.min} symbols`
    //   };
    // }

    if (control.value && !control.value.match(namePattern)) {
      return {
        error: `Invalid name.`
      };
    }

    return null;
  };

  static contactNameEmojiPattern = (contactNameEmoji) => (
    control: FormControl
  ): ValidationErrors | null => {
    if (control.value && !control.value.match(new RegExp(contactNameEmoji))) {
      return ValidatorsService.emojiPatternError();
    }

    return null;
  }

  static contactName = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const contName = config.name;
    const pattern = contName.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    const namePattern = new RegExp(pattern, 'i');

    // if (control.value.length > contName.max) {
    //   return {
    //     error: `Maximum ${contName.max} symbols are allowed`
    //   };
    // }
    //
    // if (control.value.length < contName.min) {
    //   return {
    //     error: `Should contain at least ${contName.min} symbols`
    //   };
    // }

    if (!control.value.match(namePattern)) {
      return {
        error: `Invalid name.`
      };
    }

    return null;
  };

  static phone = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const phoneNumber = config.phone_number;
    const pattern = phoneNumber.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    const phonePattern = new RegExp(pattern, 'i');

    if (control.value.length > phoneNumber.max) {
      return {
        error: `Maximum ${phoneNumber.max} symbols are allowed`
      };
    }

    if (control.value.length < phoneNumber.min) {
      return {
        error: `Should contain at least ${phoneNumber.min} symbols`
      };
    }

    if (!control.value.match(phonePattern)) {
      return {
        error: `Invalid phone number.`
      };
    }

    return null;
  };

  static bitcoinAmount = config => (
    control: FormControl
  ): ValidationErrors | null => {
    const minAmount = config.bitcoin_min_amount;
    const maxAmount = config.bitcoin_max_amount;

    if (!control.value) {
      return {
        error: 'Amount required'
      };
    }

    const pattern = '^0*[1-9][0-9]*d*(.d+)?$';
    if (!String(control.value).match(pattern)) {
      return {
        error: `Amount should contains only digits`
      };
    }

    if (control.value < minAmount || control.value % 1 !== 0) {
      return {
        error: `Amount should be at least $${minAmount}`
      };
    }

    if (control.value > maxAmount || control.value % 1 !== 0) {
      return {
        error: `Amount should be less then $${maxAmount}`
      };
    }

    return null;
  };

  static ePinAmount = config => (
    control: FormControl
  ): ValidationErrors | null => {
    const minAmount = config.epin_min_amount;

    if (!control.value) {
      return {
        error: 'Amount required'
      };
    }

    const pattern = '^0*[1-9][0-9]*d*(.d+)?$';
    if (
      !String(control.value).match(pattern) ||
      control.value < minAmount ||
      control.value % 1 !== 0
    ) {
      return {
        error: `Amount should be at least $${minAmount} and whole number`
      };
    }

    return null;
  };

  static documents = config => (
    control: FormControl
  ): ValidationErrors | null => {
    return null;
  };

  static documentInstruction = config => (
    control: FormControl
  ): ValidationErrors | null => {
    const min = +config.document_instructions.min;
    const max = +config.document_instructions.max;

    if (!control.value) {
      return null;
    }

    if (control.value && control.value.length < min) {
      return {
        error: `Should contain at least ${min} symbols`
      };
    }

    if (control.value && control.value.length > max) {
      return {
        error: `Maximum ${max} symbols are allowed`
      };
    }
  };

  static emojiPattern = (profileConfigs) => (
    control: FormControl
  ): ValidationErrors | null => {
    const emojiValidPattern = profileConfigs.visiting_text.valid_pattern;

    if (control.value && !control.value.match(new RegExp(emojiValidPattern))) {
      return ValidatorsService.emojiPatternError();
    }

    return null;
  }

  static emojiPatternError(): Record<any, string> {
    return {
      error: 'The allowed symbols are letters A-z, numbers 0-9, and special characters such as: !@#'
    };
  }

  static middleName = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const acName = config.middle_name;
    const pattern = acName.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    const namePattern = new RegExp(pattern, 'i');

    if (control.value && !control.value.match(namePattern)) {
      return {
        error: `Invalid middle name.`
      };
    }

    return null;
  };

  static firstName = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const acName = config.middle_name;
    const pattern = acName.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    const namePattern = new RegExp(pattern, 'i');

    if (control.value && !control.value.match(namePattern)) {
      return {
        error: `Invalid name.`
      };
    }

    return null;
  };

  static secretPin = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const acName = config.secret_pin;
    const pattern = acName.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    if (isReq && !control.value) {
      return {
        error: `Secret pin is required!`
      };
    }

    const namePattern = new RegExp(pattern, 'i');

    if (control.value && !control.value.match(namePattern)) {
      return {
        error: `Invalid secret pin (should contain 4 nubmers)`
      };
    }

    return null;
  };

  static nameSuffix = (config, isReq) => (
    control: FormControl
  ): ValidationErrors | null => {
    const acName = config.name_suffix;
    const pattern = acName.valid_pattern;

    if (!isReq && !control.value) {
      return null;
    }

    if (isReq && !control.value) {
      return {
        error: `Suffix is required!`
      };
    }

    const namePattern = new RegExp(pattern, 'i');

    if (control.value && !control.value.match(namePattern)) {
      return {
        error: `Invalid name suffix`
      };
    }

    return null;
  };

  static emailNotTaken(accountService: AccountService, currentEmail: string = null): AsyncValidatorFn {
    return (control: FormControl) => {
      const email = control.value;

      return timer(200).pipe(
        switchMap(() =>
          accountService.checkEmailExisting({ email }).pipe(
            map(({ exists }) =>
              (exists && email !== currentEmail) ? { error: 'This email is taken by another account!' } : null
            ),
            catchError((error: Error) =>
              of({ error: 'Server error, please try later' })
            )
          )
        )
      );
    };
  }
}
