import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '@app/_api';
import { AA_VERSION } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class AgreementGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user$.pipe(
      take(1),
      map((authUser) => {
        if (authUser.aaVersion === AA_VERSION) {
          return this.router.createUrlTree(['/my']);
        }
        return true;
      })
    );
  }
}
