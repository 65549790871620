import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

import { environment } from '@env/environment';
import { PaymentCard, PremiumAd, Cart, ShoppingCart } from '@app/models';
import { EPin } from '@app/my/epin-credit/epin-credit.component';

import { PaymentMethod } from './types/commerce.types';
import { CryptocurrencyEnum } from '@app/my/checkout-payment/checkout-payment/checkout-payment.store';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  public balance$ = new BehaviorSubject<number>(0);
  public cartItemsCount$ = new BehaviorSubject<number>(0);

  constructor(
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private readonly baseUrl: string = `${environment.apiUrl}/commerce`;

  private createHiddenInputElement(name: string, value: string): HTMLInputElement {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

  postToExternalSite(externalPostData: ExternalPostData): void {
    const form = this.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', externalPostData.actionUrl);

    const attrs = Object.assign({}, externalPostData.attrs);

    Object.keys(attrs).forEach((key) => {
      form.appendChild(this.createHiddenInputElement(key, attrs[key]));
    });

    this.document.body.appendChild(form);
    form.submit();
  }

  generateBitUrl(): Observable<{ payment_url: string }> {
    const url = `${this.baseUrl}/bitcoin/payment-url`;
    return this.httpClient.get<{ payment_url }>(url);
  }

  /*
    Returns the url of payment page
    @param {string} purchaseType - 'bitcoinpay' | 'deposit2bp'
    @param {number} amount
   */
  getPurchaseUrlWithAmount(
    amount: string,
    accountId: number
  ): Observable<{ payment_url: string }> {
    return this.generateBitUrl().pipe(
      mergeMap(({ payment_url }) => this.getPurchaseUrlWithAmountWithCurrency(payment_url, accountId, amount))
    );
  }

  getPurchaseUrlWithAmountWithCurrency(
    payment_url: string,
    accountId: number,
    amount: string,
    currency?: CryptocurrencyEnum
  ): Observable<{ payment_url: string }> {
    let url = `${this.baseUrl}${payment_url}?account_id=${accountId}`;
    if (amount) url += `&amount=${amount}`;
    if (currency) url += `&currency=${currency}`;

    return this.httpClient.get<{ payment_url: string }>(url);
  }

  getPurchaseUrl(userId: number): Observable<any> {
    const url = `${this.baseUrl}/rocketgate/hosted-purchase-page-url?account_id=${userId}&prod_id=DESKTOP`;
    return this.httpClient.get(url);
  }

  getRecommendedInovioUrl(userId: number): Observable<any> {
    const url = `${this.baseUrl}/inovio/payment-url`;
    const params = new HttpParams().set('account_id', userId.toString());
    return this.httpClient.get(url, { params });
  }

  getRecommendedEmerchantpayUrl(userId: number): Observable<any> {
    const url = `${this.baseUrl}/emerchantpay/payment-url`;
    const params = new HttpParams().set('account_id', userId.toString());
    return this.httpClient.get(url, { params });
  }

  getRecommendedUnicornUrl(userId: number): Observable<any> {
    const url = `${this.baseUrl}/unicorn/payment-url`;
    const params = new HttpParams().set('account_id', userId.toString());
    return this.httpClient.get(url, { params });
  }

  getCardList(accountId: number): Observable<PaymentCard.Model[]> {
    const url = `${this.baseUrl}/${accountId}/credit-cards`;
    return this.httpClient.get(url).pipe(
      map((json: any[]) => json.map((item) => PaymentCard.fromJson(item))),
      catchError((error: HttpErrorResponse) =>
        throwError(new Error(error.error))
      )
    );
  }

  setAutoBill(cardId: any): Observable<any> {
    const url = `${this.baseUrl}/credit-cards/${cardId}/_set-auto-bill`;

    return this.httpClient.post(url, {});
  }

  unsetAutoBill(cardId: any): Observable<any> {
    const url = `${this.baseUrl}/credit-cards/${cardId}/_unset-auto-bill`;

    return this.httpClient.post(url, {});
  }

  getAddCcUrl(accountId: number): Observable<{ url }> {
    const url = `${this.baseUrl}/rocketgate/hosted-add-cc-page-url?account_id=${accountId}&prod_id=DESKTOP`;

    return this.httpClient.get<{ url }>(url).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(new Error(res.error.message));
      })
    );
  }

  getEditCcUrl(cardId): Observable<{ url }> {
    const url = `${this.baseUrl}/rocketgate/hosted-edit-cc-page-url?prod_id=DESKTOP&card_id=${cardId}`;
    return this.httpClient.get<{ url }>(url);
  }

  getCountOfGeneratedPayments(accountId: number): Observable<number> {
    const url = `${this.baseUrl}/coinfly`;
    return this.httpClient
      .get(url, {
        params: { account_id: `${accountId}` },
      })
      .pipe(map((json: any) => json.count));
  }

  getListOfGeneratedPayments(
    accountId: number
  ): Observable<Array<PaidTransaction>> {
    const url = `${this.baseUrl}/coinfly`;
    return this.httpClient
      .post(url, {
        account_id: `${accountId}`,
      })
      .pipe(map((json: { payments: Array<PaidTransaction> }) => json.payments));
  }

  removeGeneratedPayments(accountId: number): Observable<any> {
    const url = `${this.baseUrl}/coinfly/_bulk`;
    return this.httpClient
      .delete(url, {
        params: { account_id: `${accountId}` },
      })
      .pipe(map((json: any) => json));
  }

  rocketGateCharge(value, accountId: number): Observable<any> {
    const url = `${this.baseUrl}/rocketgate/_charge`;
    const body = value;
    body.account_id = accountId;
    return this.httpClient.post(url, body);
  }

  epinPay(epinData, accountId: number): Observable<any> {
    const url = this.baseUrl + '/epin/_pay';
    const body = epinData;
    body.account_id = accountId;
    return this.httpClient.post(url, body).pipe(
      tap((res: any) => {
        if (res.status === 'ok') {
          this._snackBar.open(
            'Your balance has been successfully loaded!',
            'Close',
            {
              duration: 4000,
            }
          );
        } else {
          if (
            res.message === 'Insufficient Balance or amount value below zero'
          ) {
            this._snackBar.open('Insufficient Balance!', 'Close', {
              duration: 4000,
              panelClass: 'panel-danger',
            });
            return;
          }

          this._snackBar.open(res.message, 'Close', {
            duration: 4000,
            panelClass: 'panel-danger',
          });
        }
      }),
      catchError((error: HttpErrorResponse) => throwError(error))
    );
  }

  payZeroAmount(accountId: number): Observable<any> {
    const url = `${this.baseUrl}/cart/${accountId}/_pay`;
    return this.httpClient.post(url, {}).pipe();
  }

  deleteCard(cardId): Observable<any> {
    const url = `${this.baseUrl}/credit-cards/${cardId}`;

    return this.httpClient.delete(url).pipe(
      tap((_) =>
        this._snackBar.open('Card Successfully Deleted!', 'Close', {
          duration: 4000,
        })
      ),
      catchError((error) => throwError(error))
    );
  }

  getBalance(
    accountId: number
  ): Observable<{ balance: number; currency: string }> {
    const url = `${this.baseUrl}/${accountId}/balance`;
    return this.httpClient
      .get<{ balance; currency }>(url)
      .pipe(tap((res) => this.balance$.next(res.balance)));
  }

  getAvailablePaymentMethods(accountId: number): Observable<PaymentMethod[]> {
    const url = `${this.baseUrl}/${accountId}/available-payment-methods/new`;
    return this.httpClient.get<PaymentMethod[]>(url);
      // .pipe(map(data => data && data.filter(item => item.group !== 'credit card')));
  }

  epinCharge(value: EPin): Observable<any> {
    const url = `${this.baseUrl}/epin/_top-up-balance`;
    const body = {
      account_id: value.id,
      amount: value.amount,
      pin: value.pin,
    };
    return this.httpClient.post(url, body).pipe(
      tap((a) =>
        this._snackBar.open(a['message'] || 'successful', 'Close', {
          panelClass:
            a['status'] === 'failed' ? 'panel-danger' : 'panel-success',
          duration: 4000,
        })
      ),
      catchError((error: HttpErrorResponse) => {
        this._snackBar.open(error.message, 'Close', {
          panelClass: 'panel-danger',
          duration: 4000,
        });
        return throwError(error);
      })
    );
  }

  // getShoppingBagCountWithTotalCount
  getShoppingBagCount(accountId: number): Observable<Cart.Model> {
    const url = `${this.baseUrl}/cart/${accountId}/count`;
    return this.httpClient.get(url).pipe(
      map((res) => Cart.fromJson(res)),
      tap((res) => this.cartItemsCount$.next(res.totalCount))
    );
  }

  getShoppingCart(accountId: number): Observable<ShoppingCart.Model> {
    const url = `${this.baseUrl}/cart/${accountId}`;
    return this.httpClient
      .get(url)
      .pipe(map((res) => ShoppingCart.fromJson(res)));
  }

  getOrderByRef(refId): Observable<any> {
    const url = `${this.baseUrl}/order/ref/${refId}`;
    return this.httpClient.get(url).pipe(catchError((err) => throwError(err)));
  }

  getOrderInvoiceDataByOrderId(orderId): Observable<any> {
    const url = `${this.baseUrl}/order/${orderId}/invoice`;
    return this.httpClient.get(url).pipe(catchError((err) => throwError(err)));
  }

  getPremiumAdlist(accountId: number): Observable<PremiumAd.Model[]> {
    const url = `${this.baseUrl}/premium-ad/_bulk`;
    const body = {
      account_id: accountId,
    };
    return this.httpClient
      .post(url, body)
      .pipe(map((res: any[]) => res.map((json) => PremiumAd.fromJson(json))));
  }

  buyPremiuimService(value: any): Observable<any> {
    const url = `${this.baseUrl}/cart/premium-service`;
    return this.httpClient.post(url, value).pipe(
      catchError((error: HttpErrorResponse) => {
        let message;

        switch (error.error.message) {
          case 'PREMIUM_SERVICE_DOES_NOT_MATCH_PROFILE':
            message = `Your selected Profile doesn't match this service.`;
            break;
          case 'NO_AVAILABLE_SLOTS':
            message = 'There is no available slots at this time';
            break;
          case 'DUPLICATE_PREMIUM_SERVICE':
            message =
              'Selected Ad already has the same service associated for the same period of time.';
            break;
          case 'RESERVATION_FAILED':
            message = 'This slot is already reserved.';
            break;
          default:
            message =
              'Unexpected error occured while assigning the premium service';
            break;
        }

        this._snackBar.open(message, null, {
          panelClass: 'panel-danger',
          verticalPosition: 'top',
          duration: 4000,
        });
        return throwError(error);
      })
    );
  }

  getPremiumServiceList(accountId: number): Observable<any> {
    const url = `${this.baseUrl}/cart/${accountId}/premium-service/list`;

    return this.httpClient.get(url).pipe();
  }

  createPremiumServiceGotd(value: any): Observable<any> {
    const url = `${this.baseUrl}/cart/premium-service/gotd`;

    return this.httpClient.post(url, value).pipe();
  }

  findPremiumAdList({
    accountId,
    adIds,
    status,
  }: {
    accountId: number;
    adIds: string[];
    status: Array<'active' | 'pending'>;
  }): Observable<PremiumAd.Model[]> {
    const endpoint = `${this.baseUrl}/premium-ad/_bulk`;
    const body = {
      account_id: accountId,
      ad_ids: adIds,
      status: status,
    };

    return this.httpClient
      .post(endpoint, body)
      .pipe(map((list: any[]) => list.map((item) => PremiumAd.fromJson(item))));
  }

  getPremiumAdById(id: number): Observable<PremiumAd.Model> {
    const endpoint = `${this.baseUrl}/premium-ad/${id}`;

    return this.httpClient
      .get<PremiumAd.Model>(endpoint)
      .pipe(map((json: any) => PremiumAd.fromJson(json)));
  }

  checkFraudStatus(accountId: number, blackBox: string) {
    const endpoint = `${this.baseUrl}/iovation/check`;
    const body = {
      account_id: accountId,
      bb: blackBox,
    };

    return this.httpClient
      .post(endpoint, body)
      .pipe(map((json) => json['result']));
  }

  payWithCreditCard(body: any): Observable<any> {
    const url = `${this.baseUrl}/vendo/pay`;

    return this.httpClient.post(url, body);
  }

  /**
   * Trust Payments
   * */
  getTrustPaymentsBody(accountId: number): Observable<ICheckoutTrustPaymentsResponseData> {
    const url = `${this.baseUrl}/trust-payments/payment-body`;
    const params = new HttpParams().set('account_id', accountId.toString());

    return this.httpClient.get<ICheckoutTrustPaymentsResponseData>(url, { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        })
      );
  }

  submitTrustPaymentsForm(data: ICheckoutTrustPaymentsResponseData): void {
    const formElement = this.document.createElement('form');
    formElement.setAttribute('method', 'post');
    formElement.setAttribute('action', data.action);

    const submitButtonElement = document.createElement('input');
    submitButtonElement.setAttribute('type', 'submit');
    submitButtonElement.setAttribute('value', 'Pay');

    data.inputs.forEach(({ name, value }) => {
      const input: HTMLInputElement = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', name);
      input.setAttribute('value', value.toString());
      formElement.appendChild(input);
    });

    formElement.appendChild(submitButtonElement);

    this.document.body.appendChild(formElement);

    formElement.submit();
  }

  checkoutWithTrustPayments(accountId: number): Observable<any> {
    return this.getTrustPaymentsBody(accountId).pipe(
      tap((data) => {
        this.submitTrustPaymentsForm(data);
      }),
    );
  }
}

export interface ExternalPostData {
  actionUrl: string;
  attrs: { [s: string]: any };
}

export interface PaidTransaction {
  paymentId: number;
  amount: number;
  address: string;
  ref_id: number;
  currency: string;
}

export interface ICheckoutTrustPaymentsResponseData {
  action: string;
  inputs: Array<{ name: string, value: number }>;
}
