import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AuthService } from '@app/_api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.user$.pipe(
      take(1),
      map((authUser) => {
        if (authUser) {
          return true;
        }
        return this.router.createUrlTree(['/home'], { replaceUrl: true });
      }),
      catchError((error) => {
        return of(this.router.createUrlTree(['/home'], { replaceUrl: true }));
      }),
    );
  }

}
