import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import {
  AuthGuard,
  ConfigsGuard,
  AaVersionGuard,
  AgreementGuard,
  CanActivateWhenUnauthenticatedGuard
} from './_guards';
import { CoreComponent } from './core';
import { ImpersonationComponent } from './impersonation';
import { RecommendedComponent } from './checkout-pending';

const routes: Routes = [
  {
    path: 'inject',
    component: ImpersonationComponent,
  },
  {
    path: 'checkout-pending',
    component: RecommendedComponent,
  },
  {
    path: 'verify-email',
    data: { title: 'Email Verified | ErosAds.com' },
    loadChildren: () =>
      import('./verify-email/verify-email.module').then(
        (mod) => mod.VerifyEmailModule
      ),
  },
  {
    path: '',
    component: CoreComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'upload-documents/:accountId',
        canActivate: [AuthGuard],
        loadChildren: () => import('./upload-documents/upload-documents.module').then(md => md.UploadDocumentsModule)
      },
      {
        path: 'auth',
        canActivate: [CanActivateWhenUnauthenticatedGuard],
        loadChildren: () =>
          import('./auth/auth.module').then((mod) => mod.AuthModule),
      },
      {
        path: 'home',
        data: { title: 'Home | ErosAds.com' },
        canActivate: [CanActivateWhenUnauthenticatedGuard],
        loadChildren: () =>
          import('./home/home.module').then((mod) => mod.HomeModule),
      },
      {
        path: 'agreement',
        data: { title: 'Agreement | ErosAds.com' },
        canActivate: [AuthGuard, AgreementGuard],
        loadChildren: () =>
          import('./agreement/agreement.module').then(
            (mod) => mod.AgreementModule
          ),
      },
      {
        path: 'verify',
        data: { title: 'Verified | ErosAds.com' },
        canActivate: [CanActivateWhenUnauthenticatedGuard],
        loadChildren: () =>
          import('./verify/verify.module').then((mod) => mod.VerifyModule),
      },
      {
        path: 'recover-password',
        data: { title: 'Recover | ErosAds.com' },
        canActivate: [CanActivateWhenUnauthenticatedGuard],
        loadChildren: () =>
          import('./recover-password/recover-password.module').then(
            (mod) => mod.RecoverPasswordModule
          ),
      },
      {
        path: 'contact',
        data: { title: 'Contact | ErosAds.com' },
        loadChildren: () =>
          import('./contact/contact.module').then((mod) => mod.ContactModule),
      },
      {
        path: 'pricing',
        data: { title: 'Pricing | ErosAds.com' },
        loadChildren: () =>
          import('./pricing/pricing.module').then((mod) => mod.PricingModule),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./faq/faq.module').then((mod) => mod.FaqModule),
      },
      {
        path: 'my',
        data: { title: 'My | ErosAds.com' },
        canActivate: [AuthGuard, AaVersionGuard, ConfigsGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('./my/my.module').then((mod) => mod.MyModule),
      },
      { path: '**', redirectTo: '/home' },
    ],
  },
  { path: '**', redirectTo: '/home' },
];

const config: ExtraOptions = {
  enableTracing: false,
  // preloadingStrategy: SelectivePreloadingStrategyService,
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 0],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
