import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecommendedComponent } from './recommended.component';

@NgModule({
  declarations: [RecommendedComponent],
  imports: [CommonModule],
})
export class RecommendedModule {}
