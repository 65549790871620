export namespace PaymentCard {
  export class Model {
    accountId: number;
    address: string;
    expireMonth: number;
    expireYear: number;
    gateway: string;
    holderName: string;
    id: number;
    isAutoBill: number;
    lastFourDigits: string;
    token: string;
    type: string;
  }

  export function fromJson(json: any): Model {
    return Object.assign(new Model(), {
      accountId: json.account_id,
      address: json.address,
      expireMonth: json.expire_month,
      expireYear: json.expire_year,
      gateway: json.gateway,
      holderName: json.holder_name,
      id: json.id,
      isAutoBill: json.is_auto_bill,
      lastFourDigits: json.last_four_digits,
      token: json.token,
      type: json.type
    });
  }
}
