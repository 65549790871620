import { Inject, Injectable } from '@angular/core';
import { ScriptLoaderService } from '@app/_services/script-loader.service';
import { Observable, throwError } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { AuthService } from '@app/_api';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ZendeskHelperService } from '@app/_services/zendesk-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {

  constructor(private scriptLoaderService: ScriptLoaderService,
              private breakpointObserver: BreakpointObserver,
              private authService: AuthService,
              private http: HttpClient,
              @Inject(DOCUMENT) private document: Document) {}
  public zE = null;
  private initialized = false;
  private taskQueue: Array<any> = [];

  public isInitialized(): boolean {
    this.loginZendesk();
    return this.initialized;
  }

  private loadScript() {
    const chatScript = this.document.createElement('script');
    chatScript.type = 'text/javascript';
    chatScript.setAttribute('id', 'ze-snippet');
    chatScript.async = true;

    const key = environment.production ? 'cdbe8389-e0a1-4c6d-98d6-c09613c5708e' : 'f124d0dc-d711-4636-b99b-513ee796654d';
    chatScript.src =  `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    this.document.body.appendChild(chatScript);
  }

  public init() {
    this.loadScript();
    return this.finishLoading();
  }

  authorize() {
    return this.authService.getAuthUserSnapshot();
  }

  getAccessToken(accountId): Observable<{ token: string }> {
    const url = `${environment.apiUrl}/account/zendesk/create-access-token`;

    const params = new HttpParams().set('account_id', accountId);

    return this.http.get<{ token: string }>(url, { params })
  }

  login(token: string) {
    if (this.zE) {
      this.zE('messenger', 'loginUser', function (callback) {
        callback(token);
      });
    }
  }

  loginZendesk() {
    const accountId = this.authorize().id;
    this.getAccessToken(accountId).pipe(
      map(({ token }) => {
        return this.login(token);
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.message);
      })
    ).subscribe();
  }

  public logout() {
    if (!this.initialized) {
      this.taskQueue.push(this.logout.bind(this));
    } else {
      this.zE('messenger', 'logoutUser');
    }
  }

  public show() {
    if (!this.initialized) {
      this.taskQueue.push(this.show.bind(this));
    } else {
      this.zE('messenger:set', 'zIndex', 999999);
    }
  }

  public hide() {
    if (!this.initialized) {
      this.taskQueue.push(this.hide.bind(this));
    } else {
      this.zE('messenger:set', 'zIndex', -1);
    }
  }

  private tryToDoInQueueTasks() {
    this.taskQueue.forEach(tasks => {
      tasks.call();
    });
  }

  private finishLoading() {
    return new Observable<any>(subscriber => {
      let interval;

      interval = setInterval(() => {
        if (window['zE'] && window['zE'].version) {
          clearInterval(interval);
          this.initialized = true;
          this.zE = window['zE'];
          ZendeskHelperService.zE = window['zE'];

          this.tryToDoInQueueTasks();
          subscriber.next(this.zE);
          this.loginZendesk();
        }
      }, 100);
    });
  }
}
