import {Injectable} from '@angular/core';
import {LocalStorage} from '@app/_services';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavBadgeService {
  accountSettingsNewBadgeStartDate = new Date('5/12/2024'); // mm/dd/yyyy
  accountSettingsNewBadgeEndDate = new Date('6/12/2024'); // mm/dd/yyyy
  isAccountSettingsNewBadgeVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  accountSettingsNewBadgeKey = 'account_settings_new_badge';

  constructor(private localStorage: LocalStorage) {
    this.accountSettingsNewBadgeStartDate.setHours(0);
    this.accountSettingsNewBadgeStartDate.setMinutes(0);
    const isVisited = this.localStorage.getItem(this.accountSettingsNewBadgeKey);

    if (
      Date.now() > this.accountSettingsNewBadgeStartDate.getTime()
      && Date.now() < this.accountSettingsNewBadgeEndDate.getTime()
      && !isVisited
    ) {
      this.isAccountSettingsNewBadgeVisible$.next(true);
    }
  }

  setAccountSettingsNewBadgeKey() {
    if (Date.now() > this.accountSettingsNewBadgeStartDate.getTime()) {
      this.localStorage.setItem(this.accountSettingsNewBadgeKey, 'true');
      this.isAccountSettingsNewBadgeVisible$.next(false);
    }
  }
}
