export type MapOnlyNameOfKeys<T> = {
  [key in keyof T]: T[key] extends (number | string | null) ? any : MapOnlyNameOfKeys<T[key]>
};

export namespace Profile {}

export namespace Profile.Configs {

  export type MinMax = { min: number, max: number };
  export type TextEditorReqs =
    { allowed_attributes: boolean, allowed_tags: string[] } & MinMax;

  enum Currencies {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    CAD = 'CAD',
    AUD = 'AUD'
  }

  enum UnitType {
    Royal = 'royal',
    Metric = 'metric'
  }

  enum DocumentFlip {
    H = 'h',
    V = 'v'
  }

  export enum SocialType {
    Google = 'google',
    Facebook = 'facebook',
    Instagram = 'instagram',
    Periscope = 'periscope',
    Snapchat = 'snapchat',
    Tumblr = 'tumblr',
    Twitter = 'twitter',
    Vine = 'vine',
    Youtube = 'youtube'
  }

  enum Lang {
    En = 'en',
    It = 'it'
  }

  enum WeekDay {
    Sunday = 'sunday',
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday'
  }

  export interface Model {
    aa_version: number;
    age: MinMax;
    age_over: MinMax;
    age_over_step: number;
    age_restriction: { [specialty_category_name: string]: MinMax };
    available_for_address: MinMax;
    available_for_types: ['incall', 'outcall'];
    bio_text: TextEditorReqs;
    calling_code: { valid_pattern: string } & MinMax;
    competitor_fields: string[];
    currencies: Currencies[];
    decline_reason_types: {
      'advertiser agreement': string,
      'authentication request denied': string,
      'authentication request': string,
      'expired documents': string,
      'other': string,
      'photos': string,
      'text': string,
      'unaltered photo denied': string,
      'unaltered photo request': string,
      'url or website': string,
      'video': string
    };
    resize_thumbs: string[];
    story_image_mimetypes: string[];
    profile_image_height: MinMax;
    profile_image_width: MinMax;
    file_size: {
      document: number
      image: number
      video: number
    };
    video_mimetypes: string[];
    default_style: string;
    default_unit_type: UnitType;
    disclaimer: TextEditorReqs;
    document_degree: number[];
    document_flip: [DocumentFlip.H, DocumentFlip.V];
    document_instructions: MinMax;
    document_third_party_verified: string[]; // ["yes", "no", "n/a"]
    document_types: string[]; // ["id", "authentication photo", "unaltered photo"]
    donations: TextEditorReqs;
    email: {valid_pattern: string};
    emails_count: number;
    flagword_fields: string[];
    height_centimetre: MinMax;
    height_foot: MinMax;
    height_inch: MinMax;
    height_metre: MinMax;
    ipv4_regex: string;
    location_tags: MinMax;
    magazine_reference: TextEditorReqs;
    max_ethnicities: number;
    metric_bust_size: MinMax;
    metric_height: MinMax;
    metric_hip_size: MinMax;
    metric_waist_size: MinMax;
    metric_weight: MinMax;
    movie_reference: TextEditorReqs;
    need_approvation_fields: string[];
    object_id: {valid_pattern: string};
    offers_list: TextEditorReqs;
    outcall_radius: MinMax;
    outcall_radius_step: number;
    phone: { valid_pattern: string } & MinMax;
    phone_number: { valid_pattern: string } & MinMax;
    phone_numbers_count: number;
    profile_photos: MinMax;
    revisor_bot_account_id: number;
    royal_bust_size: MinMax;
    royal_height: MinMax;
    royal_hip_size: MinMax;
    royal_waist_size: MinMax;
    royal_weight: MinMax;
    showname: { valid_pattern: string } & MinMax;
    shredder_account_inactivity_threshold: number;
    shredder_account_no_service_threshold: number;
    social_handle: MinMax;
    social_slogans: { [key in SocialType]: string };
    social_types: SocialType[];
    social_urls: { [key in SocialType]: string };
    social_valid_patterns: { [key in SocialType]: string };
    supportedLangs: Lang[];
    tagline: TextEditorReqs;
    trans_operation_genders: string[]; // ["transsexual", "female to male transsexual"]
    unit_types: UnitType[];
    url: { valid_pattern: string };
    vip_profile_max_photos: number;
    vip_required_main_cats: string[];
    visiting_text: TextEditorReqs & { valid_pattern: string };
    websites_count: number;
    week_days: WeekDay[];
  }

  export function fromJson(json: MapOnlyNameOfKeys<Model>): Model {
    return {
      ...json,
      aa_version: json.aa_version,
      age: {
        min: parseInt(json.age.min),
        max: parseInt(json.age.max),
      },
      age_over: {
        min: parseInt(json.age_over.min),
        max: parseInt(json.age_over.max)
      },
      age_over_step: parseInt(json.age_over_step),
      age_restriction: Object.entries(json.age_restriction).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: {
          min: parseInt(value.min),
          max: parseInt(value.max)
        }
      }), {}),
      available_for_address: {
        min: parseInt(json.available_for_address.min),
        max: parseInt(json.available_for_address.max)
      },
      bio_text: {
        ...json.bio_text,
        min: parseInt(json.bio_text.min),
        max: parseInt(json.bio_text.max)
      },
      calling_code: {
        ...json.calling_code,
        min: parseInt(json.calling_code.min),
        max: parseInt(json.calling_code.max)
      },
      disclaimer: {
        ...json.disclaimer,
        min: parseInt(json.disclaimer.min),
        max: parseInt(json.disclaimer.max)
      },
      document_instructions: {
        ...json.document_instructions,
        min: parseInt(json.document_instructions.min),
        max: parseInt(json.document_instructions.max)
      },
      donations: {
        ...json.donations,
        min: parseInt(json.donations.min),
        max: parseInt(json.donations.max)
      },
      emails_count: parseInt(json.emails_count),
      height_centimetre: {
        min: parseInt(json.height_centimetre.min),
        max: parseInt(json.height_centimetre.max)
      },
      height_foot: {
        min: parseInt(json.height_foot.min),
        max: parseInt(json.height_foot.max)
      },
      height_inch: {
        min: parseInt(json.height_inch.min),
        max: parseInt(json.height_inch.max)
      },
      height_metre: {
        min: parseInt(json.height_metre.min),
        max: parseInt(json.height_metre.max)
      },
      location_tags: {
        min: parseInt(json.location_tags.min),
        max: parseInt(json.location_tags.max)
      },
      magazine_reference: {
        ...json.magazine_reference,
        min: parseInt(json.magazine_reference.min),
        max: parseInt(json.magazine_reference.max)
      },
      max_ethnicities: parseInt(json.max_ethnicities),
      metric_bust_size: {
        min: parseInt(json.metric_bust_size.min),
        max: parseInt(json.metric_bust_size.max)
      },
      metric_height: {
        min: parseInt(json.metric_height.min),
        max: parseInt(json.metric_height.max)
      },
      metric_hip_size: {
        min: parseInt(json.metric_hip_size.min),
        max: parseInt(json.metric_hip_size.max)
      },
      metric_waist_size: {
        min: parseInt(json.metric_waist_size.min),
        max: parseInt(json.metric_waist_size.max)
      },
      metric_weight: {
        min: parseInt(json.metric_weight.min),
        max: parseInt(json.metric_weight.max)
      },
      movie_reference: {
        ...json.movie_reference,
        min: parseInt(json.movie_reference.min),
        max: parseInt(json.movie_reference.max)
      },
      offers_list: {
        ...json.offers_list,
        min: parseInt(json.offers_list.min),
        max: parseInt(json.offers_list.max)
      },
      outcall_radius: {
        min: parseInt(json.outcall_radius.min),
        max: parseInt(json.outcall_radius.max)
      },
      outcall_radius_step: parseInt(json.outcall_radius_step),
      phone_number: {
        ...json.phone_number,
        min: parseInt(json.phone_number.min),
        max: parseInt(json.phone_number.max)
      },
      phone_numbers_count: parseInt(json.phone_numbers_count),
      profile_photos: {
        min: parseInt(json.profile_photos.min),
        max: parseInt(json.profile_photos.max)
      },
      revisor_bot_account_id: parseInt(json.revisor_bot_account_id),
      royal_bust_size: {
        min: parseInt(json.royal_bust_size.min),
        max: parseInt(json.royal_bust_size.max)
      },
      royal_height: {
        min: parseInt(json.royal_height.min),
        max: parseInt(json.royal_height.max)
      },
      royal_hip_size: {
        min: parseInt(json.royal_hip_size.min),
        max: parseInt(json.royal_hip_size.max)
      },
      royal_waist_size: {
        min: parseInt(json.royal_waist_size.min),
        max: parseInt(json.royal_waist_size.max)
      },
      royal_weight: {
        min: parseInt(json.royal_weight.min),
        max: parseInt(json.royal_weight.max)
      },
      showname: {
        ...json.showname,
        min: parseInt(json.showname.min),
        max: parseInt(json.showname.max)
      },
      social_handle: {
        min: parseInt(json.social_handle.min),
        max: parseInt(json.social_handle.max)
      },
      tagline: {
        ...json.tagline,
        min: parseInt(json.tagline.min),
        max: parseInt(json.tagline.max)
      },
      visiting_text: {
        ...json.visiting_text,
        min: parseInt(json.visiting_text.min),
        max: parseInt(json.visiting_text.max)
      },
      websites_count: parseInt(json.websites_count)
    };
  }
}

export namespace Profile.Attributes {
  type Categories = {[categoryId: number]: string};

  interface Attribute<T, V, I = number[]> {
    eros_category_ids: I;
    eros_ids: number[];
    type: T;
    values: V;
    _id: string;
  }

  export interface Model {
    affiliation: Attribute<'affiliation', string[]>;
    alternative: Attribute<'alternative', string[], null>;
    availability: Attribute<'availability', string[]> & Categories;
    cup_size: Attribute<'cup_size', string[], null>;
    ethnicity: Attribute<'ethnicity', string[]>;
    eye_color: Attribute<'eye_color', string[], null>;
    gender: Attribute<'gender', string[], null>;
    hair_color: Attribute<'hair_color', string[]> & Categories;
    op_status: Attribute<'op_status', string[], null>;
  }
}

export namespace Profile.MainCategory {
  export interface Model {
    eros_id: number;
    eros_type: number;
    incompatibilities: string[];
    required_genders: string[];
    value: string;
    _id: string;
  }
}

export namespace Profile.SpecialtyCategory {
  export interface Model {
    eros_id: number;
    eros_type: number;
    incompatibilities: string[];
    required_genders: string[];
    required_main_categories: string[];
    value: string;
    _id: string;
  }
}

export namespace Profile.LocationCategory {
  export interface Model {
    [id: number]: Array<{
      eros_id: number;
      eros_type: number;
      incompatibilities: number[];
      is_visible: boolean;
      required_locations: number[];
      value: string;
      _id: string;
    }>;
  }
}

export const EXCLUDED_MAIN_CATEGORIES = [
  'fantasy',
  'dancer',
  'tantra',
  'bdsm',
  'male escort'
];

export const EXCLUDED_SPECIALTY_CATEGORIES = [
  'dominant',
  'submissive',
  'switch',
  'gfe'
];

export namespace Profile.FormData {
  export interface Model {
    attributes: Profile.Attributes.Model;
    configs: Profile.Configs.Model;
    location_categories: Profile.LocationCategory.Model;
    main_categories: Array<Profile.MainCategory.Model>;
    specialty_categories: Array<Profile.SpecialtyCategory.Model>;
    styles: Array<Profile.MainCategory.Model>;
  }

  export function fromJson(json: MapOnlyNameOfKeys<Model>): Model {
    if (Array.isArray(json.main_categories)) {
      json.main_categories = json.main_categories
        .filter(item => !EXCLUDED_MAIN_CATEGORIES.includes(item.value));
    }

    if (Array.isArray(json.configs.vip_required_main_cats)) {
      json.configs.vip_required_main_cats = json.configs.vip_required_main_cats
        .filter(item => !EXCLUDED_MAIN_CATEGORIES.includes(item));
    }

    return {
      ...json,
      configs: Profile.Configs.fromJson(json.configs)
    };
  }
}


export namespace Commerce {}

export namespace Commerce.Options {
  export interface Model {
    banner: {
      image_extensions: string[],
      image_max_size: number,
      url: { valid_pattern: string }
    };
    bitcoin_min_amount: number;
    bitcoin_max_amount: number;
    credit_reasons: string[];
    currencies: string[];
    debit_reasons: string[];
    deposit2bp: {
      max_amount: number,
      min_amount: number
    };
    epin_min_amount: number;
    manual_service_renew_options: {
      admin_durations: number[],
      customer_durations: number[]
    };
    manual_services: {
      hidden: string[],
      list: {id: number, name: string}[],
      renewables: string[]
    };
    payment_methods: string[];
    premium_ad_date_activation_max_postpone_attemps: number;
    premium_ad_date_activation_maximum_delay: number;
    premium_ad_renew_options: {
      base: { admin_durations: number[], customer_durations: number[] },
      visiting: { admin_durations: number[], customer_durations: number[] }
    };
    premium_ad_short_term_extend_durations: number[];
    premium_ad_short_term_extend_price_multiplier: number[];
    premium_service_renew_options: { admin_durations: number[], customer_durations: number[] };
    refund_reasons: string[];
    refund_reasons_note_required: string[];
    rocketgate_iovation: {
      io_bbout_element_id: string,
      io_enable_rip: boolean,
      io_exclude_stm: number,
      io_install_flash: boolean,
      io_install_stm: boolean,
      script_url: string,
      script_url_dev: string
    };
    rocketgate_max_saved_cc_count: number;
  }
}
