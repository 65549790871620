import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImpersonationComponent } from './impersonation.component';

@NgModule({
  declarations: [ImpersonationComponent],
  imports: [CommonModule]
})
export class ImpersonationModule {}
