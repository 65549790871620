export namespace ContactInfo {
  export class Model {
    contactName: string;
    email: string;
    phoneNumber: number;
  }

  export function toJson(model: ContactInfo.Model): any {
    return {
      contact_name: model.contactName,
      email: model.email,
      phone_number: model.phoneNumber
    };
  }
}
