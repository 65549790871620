export namespace Photo {
  export class Model {
    id: string;
    imageId: string;
    accountId: number;
    profileId: string;
    dateCreated: Date;
    isDeleted: boolean;
    isPublished: boolean;
    dateProcessed: Date;
    declineReason: string;
    status: 'pending' | 'approved' | 'declined';
  }

  export function fromJson(json: any): Model {
    return Object.assign(new Model(), {
      id: json._id,
      imageId: json.image_id,
      accountId: json.account_id,
      profileId: json.profile_id,
      dateCreated: json.date_created,
      isDeleted: json.is_deleted,
      isPublished: json.is_published,
      dateProcessed: new Date(json.date_processed),
      declineReason: json.decline_reason,
      status: json.status
    });
  }
}

export namespace PublishedPhoto {
  export class Model {
    photoCount: number;
    id: string;
  }

  export function fromJson(json): Model {
    return {
      photoCount: json.count,
      id: json._id
    };
  }
}
