export namespace User {
  export type Preferences =
    | 'social media'
    | 'allow archives'
    | 'email watchlist'
    | 'link ads';

  export interface Requirement {
    requirement: string;
    optional: boolean;
  }

  export class Model {
    aaVersion: number | null;
    contactName: string | null;
    currency: string;
    securityQuestion: string;
    securityAnswer: string;
    email: string;
    pendingEmail: string | null;
    exp: number;
    firstName: string | null;
    iat: number;
    id: number;
    lastName: string | null;
    middleName: string | null;
    permissions: string[];
    phoneNumber: string | null;
    role: string;
    salesAccountId: number | null;
    status: string;
    username: string;
    dateCreated: Date | null;
    dateModified: Date | null;
    messageFrequency: string;
    notificationFrequency: string;
    preferences: Array<Preferences>;
    requirements: Array<Requirement | string>;
    nameSuffix: string;
    secretPin: number;
    deviceHash: string;
    tfaType: VerificationOptionEnum;
  }

  export function fromJson(json: any): Model {
    return {
      aaVersion: json.aa_version,
      contactName: json.contact_name,
      currency: json.currency,
      securityAnswer: json.security_answer,
      securityQuestion: json.security_question,
      email: json.email,
      pendingEmail: json.pending_email,
      exp: json.exp,
      firstName: json.first_name,
      iat: json.iat,
      id: json.id,
      lastName: json.last_name,
      middleName: json.middle_name,
      permissions: json.permissions,
      phoneNumber: json.phone_number,
      role: json.role,
      salesAccountId: json.sales_account_id,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      status: json.status,
      username: json.username,
      messageFrequency: json.email_message_frequency,
      notificationFrequency: json.email_notification_frequency,
      preferences: json.preferences,
      requirements: json.requirements,
      nameSuffix: json.name_suffix,
      secretPin: json.secret_pin,
      deviceHash: json.device_hash,
      tfaType: json.tfa_type
    };
  }

  export interface Agreement {
    securityQuestion: string;
    securityAnswer: string;
    currency: string;
    aaVersion: any;
    firstName: string;
    middleName: string;
    lastName: string;
    contactName: string;
    nameSuffix: string;
    phoneNumber: any;
    secretPin: any;
  }

  export function agreementToJson(obj: Agreement) {
    return {
      security_question: obj.securityQuestion,
      security_answer: obj.securityAnswer,
      currency: obj.currency,
      aa_version: obj.aaVersion,
      first_name: obj.firstName,
      middle_name: obj.middleName,
      last_name: obj.lastName,
      contact_name: obj.contactName,
      name_suffix: obj.nameSuffix,
      phone_number: obj.phoneNumber,
      secret_pin: obj.secretPin
    };
  }
}

export const AA_VERSION = 11;

export enum VerificationOptionEnum {
  Email = 'email',
  App = 'app'
}
