import { DocumentNotificationStatusEnum } from '@app/models/document.model';

export namespace Person {
  export enum VerificationStatus {
    Approved = 'approved',
    Declined = 'declined',
    NotVerified = 'not_verified',
  }

  export enum LivenessVerificationStatus {
    Requested = 'requested',
    NotVerified = 'not_verified'
  }

  export class Model {
    accountId: number;
    dateCreated: Date;
    flag: string;
    idBypass: boolean;
    name: string;
    id: string;
    isManuallyDocumentProcessing?: boolean;
    verificationStatus: VerificationStatus;
    livenessVerificationStatus: LivenessVerificationStatus;
    isDeactivated: boolean;
    lastDocStatus: DocumentNotificationStatusEnum;
  }

  export function fromJson(json): Person.Model {
    return {
      accountId: json.account_id,
      dateCreated: new Date(json.date_created),
      flag: json.flag,
      idBypass: json.id_bypass,
      name: json.name,
      id: json._id,
      verificationStatus: json.verification_status || VerificationStatus.NotVerified,
      isDeactivated: json.is_deactivated,
      livenessVerificationStatus: json.liveness_verification_status,
      lastDocStatus: json.last_doc_status
    };
  }

  // export function toJson(model: Person.Model): any {
  //   return {
  //     id: model.id,
  //     new_password: model.password,
  //     old_password: model.oldPassword,
  //   };
  // }
}
