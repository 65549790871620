export namespace Rates {
  export class Model {
    USD: number;
    EUR: number;
    GBP: number;
    CAD: number;
    AUD: number;
  }
}

export enum Rates {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  AUD = 'AUD',
}
