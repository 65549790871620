import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, fromEvent } from 'rxjs';
import { tap, switchMap, filter, map } from 'rxjs/operators';

import * as io from 'socket.io-client';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public socks: SocketIOClient.Socket;
  private socketCreated = new BehaviorSubject<SocketIOClient.Socket>(null);

  public socket$ = this.socketCreated.asObservable().pipe(filter(sock => !!sock));

  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) {
    const url = `${environment.socksUrl}/${environment.socksNamespace}`;

    this.authService.user$
      .pipe(
        tap((authUser) => {
          if (authUser) {
            const params = new HttpParams()
              .set('token', this.authService.getToken())
              .set('account_id', authUser.id.toString());

            if (!this.socks) {
              this.socks = io.connect(url, { query: params.toString() });
            } else {
              this.socks.close();
              this.socks = io.connect(url, { query: params.toString() });
            }

            this.socketCreated.next(this.socks);
          }
          else if (this.socks) {
            this.socks.close();
          }
        })
      )
      .subscribe();
  }

  public onCreditCardAdded$ = this.socket$.pipe(
    switchMap(socks => fromEvent<any>(socks, 'credit-card-added')),
    tap(_ =>
      this._snackBar.open('Card Successfully Added!', 'Close', {
        duration: 4000
      })
    )
  );

  public onCreditCardUpdated$ = this.socket$.pipe(
    switchMap(socks => fromEvent<any>(socks, 'credit-card-updated')),
    tap(_ =>
      this._snackBar.open('Card Successfully Updated!', 'Close', {
        duration: 4000
      })
    )
  );

  public onTransactionCompleted$ = this.socket$.pipe(
    switchMap(socks => fromEvent<any>(socks, 'commerce-transaction-completed'))
  );

  public commerceBalanceUpdated$ = this.socket$.pipe(
    switchMap(socks =>
      fromEvent<{ account_id: string; balance_after: number }>(socks, 'commerce-balance-updated').pipe(
        map(({ account_id, balance_after }) => ({
          account_id: parseInt(account_id),
          balance_after
        }))
      )
    )
  );

  public commerceShoppingBagUpdated$ = this.socket$.pipe(
    switchMap(socks =>
      fromEvent<{ account_id: number }>(socks, 'commerce-shopping-bag-updated')
    )
  );

  public imageReady$ = this.socket$.pipe(
    switchMap(socks => fromEvent<{ image_id: string }>(socks, 'image-ready'))
  );

  public storyVideoProgress$ = this.socket$.pipe(
    switchMap(socks => fromEvent<{ video_id: string, progress: string }>(socks, 'video-progress'))
  );

  public storyVideoProcessFinished$ = this.socket$.pipe(
    switchMap(socks => fromEvent<{ video_id: string }>(socks, 'video-process-finished'))
  );

  public storyVideoProcessErrors$ = this.socket$.pipe(
    switchMap(socks => fromEvent<{ video_id: string }>(socks, 'video-process-errors'))
  );
}
