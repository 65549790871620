import { Injectable } from '@angular/core';
import { IDENT_EXP_DATE_KEY, IDENT_KEY } from '@app/_shared/utils/storage-helper';
import { fromEvent, Observable } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';


/**
 * @desc
 * This is the base class for `LocalStorage`, `SessionStorage`.
 */
abstract class AbstractStorage implements Storage {
  readonly length: number;
  readonly eventObservable: Observable<StorageEvent>;

  protected constructor(
    protected concreteStorage: Storage
  ) {
    this.eventObservable = fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        // filter(({storageArea}) => {
        //   return storageArea === this.concreteStorage;
        // })
        shareReplay(1)
      );
  }

  // Implemented as part of Storage.
  clear(): void {
    const ident = localStorage.getItem(IDENT_KEY);
    const identExpDate = localStorage.getItem(IDENT_EXP_DATE_KEY);
    this.concreteStorage.clear();

    if (ident && identExpDate) {
      localStorage.setItem(IDENT_KEY, ident);
      localStorage.setItem(IDENT_EXP_DATE_KEY, identExpDate);
    }
  }

  // Implemented as part of Storage.
  getItem<T = any>(key: string): T | null {
    const entity = this.concreteStorage.getItem(key);

    try {
      // return JSON.parse(entity);
      return JSON.parse(entity);
    } catch (e) {
      return null;
    }
  }

  // Implemented as part of Storage.
  key(index: number): string | null {
    return this.concreteStorage.key(index);
  }

  // Implemented as part of Storage.
  removeItem(key: string): void {
    this.concreteStorage.removeItem(key);
  }

  // Implemented as part of Storage.
  setItem<T = any>(key: string, value: T): void {
    try {
      this.concreteStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // throw new Error('**');
    }
  }

  hasItem(key: string) {
    return this.getItem(key) !== null;
  }
}


@Injectable({ providedIn: 'root' })
export class LocalStorage extends AbstractStorage {
  constructor() {
    super(localStorage);
  }

  setItem<T = any>(key: string, value: T) {
    if (key === 'usertoken') {
      try {
        this.concreteStorage.setItem(key, value as unknown as string);
      } catch (error) {}
    } else {
      super.setItem<T>(key, value);
    }
  }

  getItem<T = any>(key: string): T | null {
    if (key === 'usertoken') {
      return this.concreteStorage.getItem(key) as unknown as T;
    } else {
      return super.getItem<T>(key);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class SessionStorage extends AbstractStorage {
  constructor() {
    super(sessionStorage);
  }
}
