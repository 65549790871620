import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FooterModalComponent } from './footer-modal.component';

import { environment } from '@env/environment';

@Component({
  selector: 'sc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  modals = {
    privacy: `${environment.exteriorUrl}/disclaimer/privacy`,
    trademarks: `${environment.exteriorUrl}/disclaimer/copyright`,
    aa: `${environment.exteriorUrl}/disclaimer/agreement`,
    dmca: `${environment.exteriorUrl}/disclaimer/dmca`,
    terms: `${environment.exteriorUrl}/disclaimer/terms`,
    about: `${environment.exteriorUrl}/disclaimer/disclaimer`,
    exemption: `${environment.exteriorUrl}/disclaimer/2257`,
    mediaServices: `${environment.exteriorUrl}/disclaimer/copyright`
  };

  ngOnInit() {}

  openDialog(page: string) {
    this.dialog.open(FooterModalComponent, {
      width: '600px',
      backdropClass: 'cdk-overlay-ultra-dark',
      panelClass: 'cdk-transparent-panel',
      data: {
        url: this.modals[page],
        title: page.toUpperCase()
      }
    });
  }
}
