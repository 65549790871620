import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { GestureConfig } from '@angular/material/core';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';

import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from './http-interceptors';
import { appInitializerProviders } from './_app-initializers';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { ImpersonationModule } from './impersonation';
import { RecommendedModule } from './checkout-pending';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    ImpersonationModule,
    RecommendedModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxMaskModule.forRoot()
  ],
  providers: [
    appInitializerProviders,
    httpInterceptorProviders,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' }, },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
