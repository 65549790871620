export namespace Preferences {
  export class Model {

    emailWatchlist: string;
    socialMedia: string;
    allowArchives: string;
    linkAds: string;
    id: number;
  }

  export function toJson(model: Preferences.Model): any {
    return {
      email_watchlist: model.emailWatchlist,
      social_media: model.socialMedia,
      allow_archives: model.allowArchives,
      link_ads: model.linkAds
    };
  }
}
