export namespace PremiumAd {
  export class Model {
    id: number;
    accountId: number;
    adId: string;
    amount: number;
    amountId: number;
    basicDuration: number;
    categories: string[];
    createdBy: number;
    currency: string;
    dateActivation: Date;
    dateActivationPostponeAttemps: number;
    dateExpiration: Date;
    discountAppliedBy: any;
    discountDateApplied: Date;
    discountId: number;
    discountPercentage: number;
    discountReasonId: number;
    discountType: string;
    duration: number;
    exchangeRate: number;
    finalAmount: number;
    inventoryId: number;
    isAnimatedThumbnail: number;
    isGroupAd: number;
    isRecurring: number;
    isRenew: number;
    isShortTermExtended: number;
    isVideo: number;
    isVip: number;
    locationId: number;
    orderDateCreated: Date;
    orderId: number;
    status: string;
    systemAmount: number;
    systemFinalAmount: number;
    upgradedFrom: number;
  }

  export function fromJson(json): Model {
    return {
      id: json.id,
      accountId: json.account_id,
      adId: json.ad_id,
      amount: json.amount,
      amountId: json.amount_id,
      basicDuration: json.basic_duration,
      categories: json.categories ? json.categories : [],
      createdBy: json.created_by,
      currency: json.currency,
      dateActivation: new Date(json.date_activation),
      dateActivationPostponeAttemps: json.date_activation_postpone_attemps,
      dateExpiration: new Date(json.date_expiration),
      discountAppliedBy: json.discount_applied_by,
      discountDateApplied: new Date(json.discount_date_applied),
      discountId: json.discount_id,
      discountPercentage: json.discount_percentage,
      discountReasonId: json.discount_reason_id,
      discountType: json.discount_type,
      duration: json.duration,
      exchangeRate: json.exchange_rate,
      finalAmount: json.final_amount,
      inventoryId: json.inventory_id,
      isAnimatedThumbnail: json.is_animated_thumbnail,
      isGroupAd: json.is_group_ad,
      isRecurring: json.is_requrring,
      isRenew: json.is_renew,
      isShortTermExtended: json.is_short_term_extended,
      isVideo: json.is_video,
      isVip: json.is_vip,
      locationId: json.location_id,
      orderDateCreated: new Date(json.oreder_date_created),
      orderId: json.order_id,
      status: json.status,
      systemAmount: json.system_amount,
      systemFinalAmount: json.system_final_amount,
      upgradedFrom: json.upgraded_from
    };
  }
}
