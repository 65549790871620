export namespace NetVerifiedCountries {
  export enum ID_TYPE {
    ID_CARD = 'ID_CARD',
    DRIVING_LICENSE = 'DRIVING_LICENSE',
    PASSPORT = 'PASSPORT',
    RESIDENCE_PERMIT = 'RESIDENCE_PERMIT'
  }

  export class Model {
    id: string;
    countryCode: string;
    countryName: string;
    idTypes: ReadonlyArray<{ idType: ID_TYPE }>;
  }

  export function fromJson(json: any): Model {
    return {
      id: json._id,
      countryCode: json.countryCode,
      countryName: json.countryName,
      idTypes: json.idTypes
    };
  }
}
