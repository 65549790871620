import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'sc-footer-modal',
  styles: [
    'iframe {width: 100%}',
    'iframe {min-height: 600px}',
    'iframe {border: none}'
  ],
  styleUrls: ['./footer-modal.component.scss'],
  template: `
    <div class="m-dialog-portlet">
      <!--<span class="m-dialog-close" (click)="dialogRef.close()"
        ><i class="la la-times"></i
      ></span>-->

      <!--Dialog Header-->
      <div class="m-dialog-header">
        <h3>{{ data.title }}</h3>
      </div>

      <!--Dialog Body-->
      <div class="m-dialog-body">
        <div *ngIf="isIframeLoading" class="spinner-body d-flex justify-center align-center" [style.height]="50">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>

        <iframe #outIframe [src]="data.url | safe:'resourceUrl'" id="outIframe"></iframe>
      </div>

      <!--Dialog Footer-->
      <!--<div class="m-dialog-footer with-line">-->
      <!--<div class="m-dialog-footer with-line">
        <div class="button-group">
          <button (click)="dialogRef.close()" class="btn-outline-gray">
            Cancel
          </button>
        </div>
      </div>-->
    </div>

    <div class="cdk-dark-modal-footer">
      <button (click)="dialogRef.close()" class="btn-white btn-auto-size">
        Cancel
      </button>
    </div>
  `
})
export class FooterModalComponent implements OnInit {
  isIframeLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; url: string },
    public dialogRef: MatDialogRef<FooterModalComponent>,
    public ds: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.sendMessageToIframe()
  }

  sendMessageToIframe() {
    const iframeElement = document.getElementById('outIframe') as HTMLIFrameElement;
    iframeElement.onload = () => {
      iframeElement.contentWindow && iframeElement.contentWindow.postMessage('remove-logo', '*');
      asyncScheduler.schedule(() => this.isIframeLoading = false, 50);
    };
  }
}
