import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AnnouncementDialogComponent } from './announcement-dialog.component';

export interface AnnouncementDialogConfig {
  title: string;
  textOrTemplateRef: string | TemplateRef<any>;
  confirmBtnText?: string;
  width?: string;
  disableBackdropClick?: boolean;
  isStory?: boolean;
  panelClass?: string[];
}

@Injectable({ providedIn: 'root' })
export class AnnouncementDialogService {

  constructor(
    private matDialog: MatDialog
  ) {}

  open(config: AnnouncementDialogConfig): MatDialogRef<AnnouncementDialogComponent, void> {
    return this.matDialog.open<AnnouncementDialogComponent, AnnouncementDialogConfig, void>(AnnouncementDialogComponent, {
      data: config,
      width: config.width || '600px',
      panelClass: ['cdk-transparent-panel', 'announcement-panel', config.isStory ? 'announcement-panel-story' : 'panel', ...(config.panelClass ? config.panelClass : [])],
      backdropClass: 'cdk-overlay-ultra-dark',
      closeOnNavigation: true,
      autoFocus: true,
      disableClose: config.disableBackdropClick || false
    });
  }
}
