import { EnvironmentTarget } from './target';

export const environment = {
  production: false,
  target: EnvironmentTarget.Dev,
  apiUrl: 'https://dev-api.eros.com',
  interiorUrl: 'https://dev.erosads.com',
  exteriorUrl: 'https://dev.eros.com',
  socksUrl: 'https://dev-api.eros.com',
  interiorHost: 'erosads.com.sceon.am',
  mediaUrl: 'https://dev-i.eros.com',
  socksNamespace: 'eros',
  origin: 'https://www.erosads.com.sceon.am',
  googleMapsApiKey: 'AIzaSyBY4Ag5C0IpBGZpZzhH4wd-3B-eMv3pR_w',
  sumSubUrl: 'https://api.sumsub.com',
  sumSubFlowName: 'basic-kyc',
};

