import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ProgressBar extends Subject<boolean> {

  constructor() {
    super();
  }

  dispatch(isLoading: boolean) {
    this.next(isLoading);
  }

}
