import { environment } from '@env/environment';
import { Person } from '@app/models/person.model';

export enum DocumentType {
  Id = 'id',
  AuthenticationPhoto = 'authentication photo',
  UnalteredPhoto = 'unaltered photo',
}

export namespace Doc {
  export interface Model {
    accountId: number;
    createdBy: number;
    dateCreated: Date;
    ext: 'jpg' | 'jpeg' | 'png';
    filename: string;
    hash: string;
    path: string;
    purpose: string;
    id: string;
    type?: DocumentType;
  }

  export function fromJson(json: any): Model {
    return {
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: new Date(json.date_created),
      ext: json.ext,
      filename: json.filename,
      hash: json.hash,
      path: json.path,
      purpose: json.purpose,
      id: json._id,
      type: json.type
    };
  }

  export function srcFrom(image: Doc.Model): string {
    return `${environment.mediaUrl}/${image.path}`;
  }
}

export namespace DocumentsCreate {
  export interface Model {
    accountId: number;
    createdBy: number;
    dateCreated: Date;
    documents: Array<{ [key: string]: string; }>;
    isDeleted: boolean;
    personId: string;
    status: string;
    id: string;
    type: DocumentType;
    birthDate?: string;
    idNumber?: string;
    isAcceptable: boolean;
  }

  export function fromJson(json: any): Model {
    return {
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: json.date_created,
      documents: json.documents,
      isDeleted: json.is_deleted,
      isAcceptable: json.is_acceptable,
      personId: json.person_id,
      status: json.status,
      id: json._id,
      type: json.type
    };
  }

  export function toJson(model: Partial<Model>): any {
    const data = {
      account_id: model.accountId,
      personId: model.personId,
      documents: model.documents
    };

    if (model.birthDate) {
      data['birth_date'] = model.birthDate;
      data['id_number'] = model.idNumber;
    }

    return data;
  }
}

export enum DocumentPhotoType {
  ID = 'id',
  AuthenticationPhoto = 'authentication, photo',
  UnalteredPhoto = 'unaltered, photo',
}

export class DocumentNotificationModel {
  id: string;
  isSeen: boolean;
  documentId: string;
  status: DocumentNotificationStatusEnum;
  personId: string;
  dateCreated: Date;
  accountId: number;
  person?: Person.Model;

  static fromJSON(json: any): DocumentNotificationModel {
    return {
      id: json._id,
      isSeen: json.is_seen,
      documentId: json.document_id,
      status: json.status,
      personId: json.person_id,
      dateCreated: new Date(json.date_created),
      accountId: json.account_id
    };
  }
}

export enum DocumentNotificationStatusEnum {
  Approved = 'approved',
  NotVerified = 'not_verified',
  Declined = 'declined',
  Pending = 'pending',
  Expired = 'expired',
}
