import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from '@app/_api/auth.service';


@Injectable({providedIn: 'root'})
export class LinkExchangeService {

  private readonly baseUrl: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.baseUrl = `${environment.exteriorUrl}/api/exchange-links`;
  }

  getBannerByRefId(ref_id: number): Observable<Array<{src: string, href: string}>> {
    const params = new HttpParams()
      .set('ad_id', ref_id.toString());

    return this.http
      .get<Array<{src: string, href: string}>>(this.baseUrl, {params})
      .pipe(catchError(this.handleError<any>()));
  }

  getBannerByLocIdAndCats(cat: string, locationId: number): Observable<Array<{src: string, href: string}>> {
    const params = new HttpParams()
      .set('category', cat)
      .set('location_id', locationId.toString());

    return this.http
      .get<Array<{src: string, href: string}>>(this.baseUrl, {params})
      .pipe(catchError(this.handleError<any>()));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errMsg = error.message
        ? error.message
        : error.status
          ? `${operation} failed: ${error.status} - ${error.message}`
          : 'Server error';
      console.error(errMsg);
      return of(result as T);
    };
  }
}
