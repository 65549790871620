import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from '@app/_services';
import { AuthService } from '@app/_api';

@Component({
  selector: 'sc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() isLogged: boolean;
  @Input() unableLogout: boolean;

  @Output() logOut: EventEmitter<any> = new EventEmitter();

  layoutConfig = this.layoutService.layoutConfig;

  constructor(
    public layoutService: LayoutService,
    public authService: AuthService
  ) {}

  toggleAside() {
    this.layoutConfig.set('showSideNav', !this.collapsed);
  }

  get collapsed() {
    return this.layoutConfig.get('showSideNav');
  }
}
