import { Injectable } from '@angular/core';
import { IdenfyStatusEnum } from '@app/_shared/modules/idenfy-document-uploader/types';
import { environment } from '@env/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, of, ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material';

import { AuthService } from './auth.service';
import {
  DocumentsCreate,
  Person,
  Profile,
  ProfilePhoto,
  ProfileRevision,
  ProfileStyle,
  Revision,
  NetVerifiedCountries,
  DocumentPhotoType,
  DocumentNotificationModel
} from '@app/models';


@Injectable({ providedIn: 'root' })
export class ProfileService {

  private readonly baseUrl: string;

  readonly PROFILE_CHANGES_MAX_COUNT: number = 3;

  netVerifiedCountries$: Observable<NetVerifiedCountries.Model[]>;
  documentsNotificationCount$: ReplaySubject<number> = new ReplaySubject(0);

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar
  ) {
    this.baseUrl = `${environment.apiUrl}/escort-profile`;
  }

  // -----------------------------------------------
  // ---------- Net Verified Countries -------------
  // -----------------------------------------------
  getNetverifyCountries(): Observable<NetVerifiedCountries.Model[]> {
    if (this.netVerifiedCountries$) {
      return this.netVerifiedCountries$;
    }

    const url = `${environment.apiUrl}/profile/document/shuftipro/countries`;
    return (this.netVerifiedCountries$ = this.httpClient.get(url).pipe(
      map((res: []) =>
        res
          .map(item => NetVerifiedCountries.fromJson(item))
          .sort((a, b) => {
            return a.countryName < b.countryName
              ? -1
              : a.countryName > b.countryName
                ? 1
                : 0;
          })
      )
    ));
  }

  createSumSubAccessToken(personId: string): Observable<{ token: string }> {
    const url = `${environment.apiUrl}/escort-profile/document/sumsub/create-access-token`;

    return this.httpClient.post<{ token: string }>(url, { person_id: personId });
  }

  createIdenfyAccessToken(personId: string): Observable<{ url: string, status: IdenfyStatusEnum }> {
    const url = `${environment.apiUrl}/escort-profile/document/idenfy/create-access-token`;

    return this.httpClient.post<{ url: string, status: IdenfyStatusEnum }>(url, { person_id: personId });
  }

  createDocument(value: any): Observable<DocumentsCreate.Model> {
    const url = `${this.baseUrl}/documents`;
    const body: any = {
      documents: [{ id: value.firstFile.body.id }],
      instructions: value.instructions ? value.instructions : null,
      person_id: value.person,
      type: value.type
    };

    if (value.type === 'id') {
      body.country_code = value.netVerCountries;
      body.id_type = value.idTypes;
      body.documents.push({ id: value.secFile.body.id });
    }

    return this.httpClient.post<{id: string}>(url, body).pipe(
      map(({id}) => ({
        id: id,
        accountId: this.authService.getAuthUserSnapshot().id,
        createdBy: this.authService.getAuthUserSnapshot().id,
        dateCreated: new Date(),
        documents: body.documents,
        isDeleted: false,
        isAcceptable: true,
        personId: body.person_id,
        status: 'pending',
        type: body.type
      } as DocumentsCreate.Model)),
      tap(res => {
        // this.matSnackBar.open('Your document successfully uploaded!', 'Close', {
        //   panelClass: 'panel-success',
        //   duration: 5000
        // });
      }),
      catchError(error => {
        this.matSnackBar.open(error.error.message, 'Close', {
          panelClass: 'panel-danger',
          duration: 5000
        });
        return throwError(error);
      })
    );
  }

  createDocumentManual(body: {
    person_id: string;
    documents: {id: string}[];
    type: DocumentPhotoType;
    id_type: NetVerifiedCountries.ID_TYPE;
    instructions: null
  }): Observable<DocumentsCreate.Model> {
    const url = `${this.baseUrl}/documents`;

    return this.httpClient.post<{id: string}>(url, body).pipe(
      map(({id}) => ({
        id: id,
        accountId: this.authService.getAuthUserSnapshot().id,
        createdBy: this.authService.getAuthUserSnapshot().id,
        dateCreated: new Date(),
        documents: body.documents,
        isDeleted: false,
        isAcceptable: true,
        personId: body.person_id,
        status: 'pending',
        type: body.type as string
      } as DocumentsCreate.Model)),
      catchError(error => {
        this.matSnackBar.open(error.error.message, 'Close', {
          panelClass: 'panel-danger',
          duration: 5000
        });
        return throwError(error);
      })
    );
  }

  createDocuments(data: Array<{
    documents: Array<any>,
    instructions?: string
    person_id: string
    type: string
  }>): Observable<DocumentsCreate.Model[]> {
    const url = `${this.baseUrl}/documents/_bulk`;
    const body: any = { docs: data };

    return this.httpClient.post<
      Array<{id: string, person_id: string, type: string, documents: {id: string}[]}>
    >(url, body).pipe(
      map((relations) =>
        relations.map(({id, person_id, type, documents}) => ({
          id: id,
          accountId: this.authService.getAuthUserSnapshot().id,
          createdBy: this.authService.getAuthUserSnapshot().id,
          dateCreated: new Date(),
          documents: documents,
          isDeleted: false,
          isAcceptable: true,
          personId: person_id,
          status: 'pending',
          type: type
        } as DocumentsCreate.Model))
      ),
      catchError(error => {
        // this.matSnackBar.open(error.error.message, 'Close', {
        //   panelClass: 'panel-danger',
        //   duration: 5000
        // });
        return throwError(error);
      })
    );
  }

  // -----------------------------------------------
  // ----------------- Profiles --------------------
  // -----------------------------------------------
  /**
   * @return created profile object id
   * */
  createProfile(
    data: {
      accountId: number,
      personId: string,
      isMulti: boolean,
      isFast: boolean
    }
  ) {
    const endpoint = `${this.baseUrl}`;
    const {
      accountId,
      personId,
      isMulti,
      isFast
    } = data;

    if (isMulti === true && personId !== null) {
      throw new Error(
        `When "is_multi" is true "person_id" should be null. But personId is ${personId}`
      );
    }

    const body = {
      account_id: accountId,
      person_id: personId,
      is_multi: isMulti,
      is_fast: isFast
    };

    return this.httpClient.post<{ id: string }>(endpoint, body);
  }

  /**
   * @param id can be ObjectId (string) or referenceId (int)
   * */
  getProfileById(id: string | number) {
    const endpoint = `${this.baseUrl}/${id}`;

    return this.httpClient.get(endpoint).pipe(
      map(json => Profile.fromJson(json)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of(null);
        }
        return throwError(new Error(error.error));
      })
    );
  }

  /**
   * @param id can be ObjectId (string) or referenceId (int)
   * */
  deleteProfilesByIds(id: string | number) {
    const endpoint = `${this.baseUrl}/${id}`;

    return this.httpClient.delete<{ id: string }>(endpoint);
  }

  /**
   * @param id can be ObjectId (string) or referenceId (int)
   * */
  revertProfile(id: string | number) {
    const endpoint = `${this.baseUrl}/${id}/_revert`;

    return this.httpClient.post<{ id: string }>(endpoint, {});
  }

  /**
   * @param id can be ObjectId (string) or referenceId (int)
   * @param data "person_id" is required only for single profiles and when "copy_photos" is false.
   * */
  copyProfile(
    id: string | number,
    data: { copyPhotos: boolean; personId?: string }
  ) {
    const endpoint = `${this.baseUrl}/${id}/_copy`;

    if (data.personId && data.copyPhotos === true) {
      throw new Error(
        `"person_id" is required only for single profiles and when "copy_photos" is false. But provided copy_photos=true`
      );
    }

    const body = { copy_photos: data.copyPhotos };
    if (data.personId) body['person_id'] = data.personId;

    return this.httpClient.post<{ profile_id: string }>(endpoint, body);
  }

  /**
   * @param accountId
   * @param personId When person_id is provided but is empty multi profiles will be returned
   * */
  findProfiles(accountId: number, personId: string = null) {
    const endpoint = `${this.baseUrl}`;
    let params = new HttpParams().set('account_id', accountId.toString());
    if (personId) {
      params = params.set('person_id', personId);
    }

    return this.httpClient
      .get(endpoint, { params })
      .pipe(map((json: any[]) => json.map(item => Profile.fromJson(item))));
  }

  /**
   * @param profileIds All profiles in array HAVE TO belong to the same account.
   * */
  makeProfileAvailableNow(profileIds: Array<{ id: string }>) {
    const endpoint = `${this.baseUrl}/_available-now`;
    return this.httpClient.post(endpoint, profileIds);
  }

  /**
   * @param profileIds All profiles in array HAVE TO belong to the same account.
   * */
  revokeProfileAvailableNow(profileIds: Array<{ id: string }>) {
    const endpoint = `${this.baseUrl}/_available-now-off`;
    return this.httpClient.post(endpoint, profileIds);
  }

  updateProfileOptions() {}

  switchProfileToFast(profileId: string) {
    const endpoint = `${this.baseUrl}/${profileId}/_switch-to-fast`;
    return this.httpClient.post<any>(endpoint, {});
  }

  getLast24HourChangesCount(
    accountId: number,
    profileId: string,
    priorities: Array<'priority 1' | 'priority 2' | 'priority 3'>
  ): Observable<number> {
    const url = `${environment.apiUrl}/ticket/count-by-profile-id`;

    const body = {
      account_id: accountId,
      profile_id: profileId,
      priorities
    };

    return this.httpClient.post<number>(url, body)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          // premium ad on this profile not found scenario
          if (errorResponse.status === 404) {
            return of(null);
          }

          return throwError(errorResponse.error);
        })
      );
  }

  // -----------------------------------------------
  // ----------------- Persons ---------------------
  // -----------------------------------------------
  createPerson(name: string, accountId: number) {
    const endpoint = `${this.baseUrl}/persons`;
    const body = { name, account_id: accountId };

    return this.httpClient.post<{ id: string }>(endpoint, body);
  }

  editPerson(personId: string, data: { name?: string; idBypass?: string }) {
    const endpoint = `${this.baseUrl}/person/${personId}`;
    const body = {};
    if (data.name) body['name'] = data.name;
    if (data.idBypass) body['id_bypass'] = data.idBypass;

    return this.httpClient.put<{}>(endpoint, body);
  }

  getPersonById(personId: string) {
    const endpoint = `${this.baseUrl}/person/${personId}`;

    return this.httpClient
      .get(endpoint)
      .pipe(map(json => Person.fromJson(json)));
  }

  getPersons(accountId: number) {
    const endpoint = `${this.baseUrl}/persons`;
    const params = new HttpParams().set('account_id', accountId.toString());

    return this.httpClient
      .get(endpoint, { params })
      .pipe(map((json: any[]) =>
        json
          .map(item => Person.fromJson(item))
          .filter((person) => !person.isDeactivated))
      );
  }

  makePersonAuthenticated(personId: string) {
    const endpoint = `${this.baseUrl}/person/${personId}/_authenticated`;
    return this.httpClient.post(endpoint, {});
  }

  revokePersonAuthenticated(personId: string) {
    const endpoint = `${this.baseUrl}/person/${personId}/_authenticated`;
    return this.httpClient.delete(endpoint, {});
  }

  getPersonAuthRequirements(
    requirements: any[],
    docs: any[]
  ) {
    const result = { requirement: 'authentication photo', optional: true };

    if (requirements.includes('auth photo')) {
      result.optional = false;
    }

    // excludePersonRequirements
    result.optional = (() => {
      const approvedIdDoc = docs.some(doc =>
        doc.type === result.requirement &&
        ['pending', 'approved'].includes(doc.status)
      );

      return approvedIdDoc ? true : result.optional;
    })();

    return result;
  }

  // ---------------------------------------------
  // ----------------- Documents -----------------
  // ---------------------------------------------

  getDocuments(): Observable<Array<DocumentsCreate.Model>> {
    const url = `${this.baseUrl}/documents/?account_id=${this.authService.getAuthUserSnapshot().id}`;
    return this.httpClient
      .get(url)
      .pipe(map((json: any[]) => json.map(d => DocumentsCreate.fromJson(d))));
  }

  getDocumentTypes(): Observable<any> {
    const url = `${this.baseUrl}/configs`;
    return this.httpClient.get(url);
  }

  uploadDocument(value: any) {
    const url = `${this.baseUrl}/documents`;
    const body = value;
    return this.httpClient.post(url, body);
  }

  // ---------------------------------------------
  // ----------------- Revision -----------------
  // ---------------------------------------------

  putProfileRevision(revision: Profile.Model): Observable<Profile.Model> {
    const endpoint = `${this.baseUrl}/rev`;
    const json = Profile.toJson(revision);

    return this.httpClient.put(endpoint, json).pipe(
      map(({id}: {id: string}) => {
        if (
          [ProfileRevision.Status.APPROVED, ProfileRevision.Status.DECLINED]
            .includes(revision.revisionMeta.status)
        ) {
          return {
            ...revision,
            revisionMeta: {
              ...revision.revisionMeta,
              status: ProfileRevision.Status.PENDING
            }
          };
        }

        return revision;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error);
      })
    );
  }

  createRevision(revision: Profile.Model) {
    const endpoint = `${this.baseUrl}/rev`;
    const json = Profile.toJson(revision);

    return this.httpClient.put<{id: string}>(endpoint, json).pipe(
      map(({id}) => ({
        ...revision,
        id,
        revisionMeta: {
          status: ProfileRevision.Status.PENDING,
          number: 1,
          createdBy: this.authService.getAuthUserSnapshot().id,
          processedBy: null,
          modifiedBy: null,
          isModifiable: true,
          isApprovable: false,
          isDeleted: false,
          dateCreated: new Date(),
          dateModified: null
        }
      })),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error);
      })
    );
  }

  getLastRev(profileId: any): Observable<Profile.Model> {
    const url = `${this.baseUrl}/rev/latest/?profile_id=${profileId}`;
    return this.httpClient
      .get(url)
      .pipe(map((json: any) => Profile.fromJson(json)));
  }

  getAllProfileRev(profileIds: any[]): Observable<Array<Revision.Model>> {
    const url = this.baseUrl + '/revs/latest/_bulk';
    const body = {
      account_id: this.authService.getAuthUserSnapshot().id,
      profile_ids: profileIds
    };
    return this.httpClient
      .post(url, body)
      .pipe(map((json: any[]) => json.map(r => Revision.fromJson(r))));
  }

  // ---------------------------------------------
  // ----------------- Photos -----------------
  // ---------------------------------------------
  getPhotoById(photoId: string) {
    const endpoint = `${this.baseUrl}/photo/${photoId}`;
    return this.httpClient
      .get(endpoint)
      .pipe(map(json => ProfilePhoto.fromJson(json)));
  }

  getAllPhotos(photo_ids: any[], accountId) {
    const endpoint = `${this.baseUrl}/photos/_bulk`;

    const body = {
      account_id: accountId,
      photo_ids: photo_ids
    };

    return this.httpClient.post(endpoint, body).pipe(
      map((json: any[]) => json.map(item => ProfilePhoto.fromJson(item))),
      catchError(err => throwError(err))
    );
  }

  createPhotos(imageIds: string[], accountId: number, personId: string) {
    const endpoint = `${this.baseUrl}/photos`;
    const body = {
      image_ids: imageIds,
      account_id: accountId,
      person_id: personId
    };
    if (!personId) delete body.person_id;

    return this.httpClient.post<{photo_id: string, image_id: string, number: number}[]>(endpoint, body)
      .pipe(
        map(res =>
          res.map(item => ({
            id: item.photo_id,
            imageId: item.image_id,
            status: 'pending',
            number: item.number,
            personId: personId,
            dateCreated: new Date()
          }))
        ),
        catchError(err => throwError(err))
      );
  }

  deletePhotos(photoIds: string[]) {
    const endpoint = `${this.baseUrl}/photos`;
    const params = photoIds.reduce((acc, id) => acc.append('photo_ids[]', id), new HttpParams());

    return this.httpClient.delete(endpoint, {params}).pipe();
  }

  getStyleList() {
    const endpoint = `${this.baseUrl}/styles`;
    return this.httpClient.get(endpoint).pipe(
      map((json: Array<any>) =>
        json.map(style =>
          ProfileStyle.fromJson(style)
        )
      )
    );
  }

  reUseRevision(account_id, profile_id) {
    const url = `${environment.apiUrl}/escort-profile/rev`;
    return this.httpClient.put(url, { account_id, profile_id });
  }

  checkPendingDocument(personId: string): Observable<boolean> {
    const url = `${this.baseUrl}/documents/check-pending/${personId}`;
    return this.httpClient.get<boolean>(url);
  }

  getAccountDocumentList(accountId: number, offset: number, limit): Observable<{ notifications: DocumentNotificationModel[], count: number }> {
    const params = new HttpParams()
      .set('account_id', accountId.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString());
    const url = `${this.baseUrl}/documents/notifications`;
    return this.httpClient.get<{ notifications: DocumentNotificationModel[], count: number }>(url, { params })
      .pipe(map(data => {
        data.notifications = data.notifications.map(json => DocumentNotificationModel.fromJSON(json))
        return data;
      }));
  }

  getDocumentsNotificationsCount(accountId: number): Observable<{ count: number }> {
    const params = new HttpParams()
      .set('account_id', accountId.toString());
    const url = `${this.baseUrl}/documents/notifications/count`;
    return this.httpClient.get<{ count: number }>(url, { params });
  }

  updateDocumentNotification(notificationId: string, body: { is_seen: boolean }): Observable<boolean> {
    const url = `${this.baseUrl}/documents/notifications/${notificationId}`;
    return this.httpClient.patch<boolean>(url, body);
  }
}
