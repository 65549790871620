import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ZendeskHelperService {
  static isElementChecked = false;
  static hideTimeouts = [];
  static showTimeouts = [];
  static zE = null;

  static showWidget() {
    this.hideTimeouts.forEach(item => clearTimeout(item));
    this.hideTimeouts = [];

    if (this.zE) {
      this.zE('messenger', 'show');
      const widgetIframe = this.getWidgetIframe();

      if (widgetIframe) {
        widgetIframe.parentElement.style.display = 'initial';
      }
    } else {
      this.showTimeouts.push(setTimeout(() => this.showWidget(), 100));
    }
  }

  static hideWidget() {
    this.showTimeouts.forEach(item => clearTimeout(item));
    this.showTimeouts = [];

    if (this.zE) {
      this.zE('messenger', 'hide');
      const widgetIframe = this.getWidgetIframe();

      if (widgetIframe) {
        widgetIframe.parentElement.style.display = 'none';
      }
    } else {
      this.hideTimeouts.push(setTimeout(() => this.hideWidget(), 100));
    }
  }

  static getWidgetIframe(): HTMLIFrameElement | undefined {
    const iframes: HTMLIFrameElement[] = Array.from(document.body.querySelectorAll('iframe[title]'));
    return iframes.find((item: HTMLIFrameElement) => item.title && item.title.toString().includes('Button to launch messaging window'));
  }

  static warnAboutTitleChanges() {
    if (!environment.production) {
      setTimeout(() => {
        this.isElementChecked = true;
        if (!this.getWidgetIframe()) {
          alert(`Zendesk Widget's iframe title changed! Please correct the title.`);
          console.log(`%cZendesk Widget's iframe title changed! Please correct the title.`, `color: red; font-size:150px; font-weight: bold;`);
        }
      }, 5000);
    }
  }
}
