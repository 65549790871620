import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';

import { LayoutService } from '@app/_services';
import { AuthService, CommerceService, MessagesService, ProfileService } from '@app/_api';
import {SideNavBadgeService} from '@app/core/sidenav/side-nav-badge.service';

@Component({
  selector: 'sc-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Output() logOut: EventEmitter<boolean> = new EventEmitter();

  authUser$ = this.authService.user$;
  messagesCount$ = this.messagesService.messagesCount$;
  balance$ = this.commerceService.balance$;
  currency$ = this.authService.currency$;
  shoppingBagCount$ = this.commerceService.cartItemsCount$;
  canUploadDocuments$ = this.authService.isLoggedInAs$$(['customer', 'admin', 'manager']);
  documentsNotificationCount$ = this.profileService.documentsNotificationCount$;

  get collapsed() {
    return this.layoutService.layoutConfig.get('showSideNav');
  }

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private messagesService: MessagesService,
    private commerceService: CommerceService,
    private layoutService: LayoutService,
    public sideNavBadgeService: SideNavBadgeService
  ) {
  }

  ngOnInit() {
    this.authService.user$
      .pipe(
        take(1),
        switchMap(account => this.profileService.getDocumentsNotificationsCount(account.id)),
      )
      .subscribe(data => {
        this.documentsNotificationCount$.next(data.count);
      });
  }

  onLogOut() {
    this.logOut.emit(true);
  }

  toggleAside() {
    this.layoutService.layoutConfig.set('showSideNav', !this.collapsed);
  }
}
