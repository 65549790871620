import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ConfirmDialogModule } from '@app/_shared/dialogs/confirm-dialog';
import { AnnouncementDialogModule } from '@app/_shared/dialogs/announcement-dialog';
import { PipesModule } from '@app/_shared/pipes';

import { CoreComponent } from './core.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterModalComponent } from './footer/footer-modal.component';
import { LogoutDialogComponent } from './logout-dialog';
import { MatProgressSpinnerModule } from '@angular/material';
import {
  TwoFaDeviceVerificationDialogComponent
} from '@app/my/settings/two-fa-device-verification-dialog/two-fa-device-verification-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMaskModule} from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,
    ConfirmDialogModule,
    AnnouncementDialogModule,
    PipesModule,
    MatProgressSpinnerModule,


    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule,
  ],
  declarations: [
    CoreComponent,
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    FooterModalComponent,
    LogoutDialogComponent,
    TwoFaDeviceVerificationDialogComponent
  ],
  entryComponents: [
    FooterModalComponent,
    LogoutDialogComponent,
    TwoFaDeviceVerificationDialogComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, this.constructor.name);
  }
}
