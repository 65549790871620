import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable, throwError, concat, of } from 'rxjs';
import { catchError, map, retryWhen, take, delay } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Image, Doc } from '@app/models';

import { AuthService } from './auth.service';

interface InitialState {
  cropping: boolean;
  cropped: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private readonly baseUrl: string = `${environment.apiUrl}/media`;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  /** Overloading this.uploadImage method */
  uploadProfileImage(
    file: File,
    accountId: number,
    purpose = 'profile',
    cId: string
  ): Observable<{ imageId: string, cId: string }> {

    const endpoint = `${this.baseUrl}/images`;
    const params = new HttpParams()
      .set('purpose', purpose)
      .set('account_id', accountId.toString());

    const formData = this.createFormData(file);

    return this.httpClient.post(endpoint, formData, {params: params}).pipe(
      map((res: {id: string}) => ({ imageId: res.id, cId: cId })),
      catchError((error: HttpErrorResponse) => throwError({
        msg: error.error.message,
        fileName: file.name,
        cId: cId
      }))
    );
  }

  uploadImage(
    file: File,
    accountId: number,
    purpose = 'profile' /*, more...*/,
    cId: string
  ): Observable<{
    body: { id: string };
    msg: string;
    status: string;
    percentDone: number;
    cId: string;
    error?: any
  }> {
    const endpoint = `${this.baseUrl}/images`;
    const params = new HttpParams()
      .set('purpose', purpose)
      .set('account_id', accountId.toString());

    const formData = this.createFormData(file);

    return this.httpClient.post(endpoint, formData, {
        params: params,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => this.getEventMessage(event, file, cId)),
        catchError((error: HttpErrorResponse) => {
          // Todo handle status code
          return throwError({error, cId: cId });
        })
      );
  }

  uploadDocuments(
    file: File,
    accountId: number,
    cId: string /*, more...*/,
    purpose = 'verification'
  ): Observable<{
    body: { id: string };
    msg: string;
    status: string;
    percentDone: number;
    cId: string;
  }> {
    const endpoint = `${this.baseUrl}/documents`;
    const params = new HttpParams()
      .set('purpose', purpose)
      .set('account_id', accountId.toString());

    const formData = this.createFormData(file);

    return this.httpClient
      .post(endpoint, formData, {
        params: params,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => this.getEventMessage(event, file, cId)),
        catchError((error: HttpErrorResponse) => {
          // Todo handle status code
          return throwError({ ...error, cId: cId });
        })
        // catchError(this.handleError(file))
      );
  }

  private createFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('files[]', file, file.name);
    return formData;
  }

  private getEventMessage(event: HttpEvent<any>, file: File, cId) {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          msg: `Uploading file "${file.name}" of size ${file.size}.`,
          status: 'pending',
          percentDone: 0,
          body: null,
          cId: cId
        };

      case HttpEventType.UploadProgress:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return {
          msg: `File "${file.name}" is ${percentDone}% uploaded.`,
          percentDone: percentDone,
          status: 'pending',
          body: null,
          cId: cId
        };

      case HttpEventType.Response:
        return {
          msg: `File "${file.name}" was completely uploaded!`,
          percentDone: 100,
          status: 'finished',
          body: event.body,
          cId: cId
        };

      default:
        return {
          msg: `Finished ${file.name} of size ${file.size} uploading.`,
          status: 'pending',
          percentDone: 100,
          body: null,
          cId: cId
        };
    }
  }

  findImagesByIds(
    ids: string[],
    thumbName: Image.ThumbName
  ): Observable<Image.Model[]> {
    const endpoint = `${this.baseUrl}/images/_bulk`;

    const body = { ids, thumb_name: thumbName };

    return this.httpClient.post(endpoint, body).pipe(
      map((json: any[]) => {
        return json.map(item => {
          if (item.is_adjusted) {
            item.path = item.path + '?' + Math.random();
          }

          return Image.fromJson(item);
        });
      })
    );
  }

  // adjustImage(imageId: string, adjust: Image.AdjustModel): Observable<any> {
  //   const endpoint = `${this.baseUrl}/image/${imageId}/_adjust`;
  //   const formData: any = new FormData();
  //   formData.append('resize_thumb', 'thumb_420');
  //   formData.append('coordinates[x]', adjust.coordinates.x);
  //   formData.append('coordinates[y]', adjust.coordinates.y);
  //   formData.append('scale', adjust.scale);

  //   if (adjust.degree) formData.append('degree', adjust.degree);

  //   return this.httpClient.post(endpoint, formData).pipe(
  //     map(json => json),
  //     catchError((res: HttpErrorResponse) => {
  //       // Todo handle status code
  //       return throwError(new Error(res.error.message));
  //     })
  //   );
  // }

  getDocumentById(docId: string): Observable<Doc.Model> {
    const endpoint = `${this.baseUrl}/document/${docId}`;

    return this.httpClient.get(endpoint).pipe(
      delay(500),
      map(json => Doc.fromJson(json)),
      retryWhen(errors =>
        concat(
          errors.pipe(
            delay(500),
            take(5)
          ),
          throwError(errors)
        )
      )
    );
  }

  findDocumentsByIds(ids: string[]): Observable<any> {
    const endpoint = `${this.baseUrl}/documents/_bulk`;
    const body = { ids };
    return this.httpClient.post(endpoint, body).pipe(
      map((json: any[]) => {
        return json.map(item => Image.fromJson(item));
      })
    );
  }

  getImageById(
    imageId: string,
    thumbName: string
  ): Observable<Image.Model> {
    const endpoint = `${this.baseUrl}/image/${imageId}`;
    const params = new HttpParams().set('thumb_name', thumbName);

    return this.httpClient
      .get(endpoint, { params })
      .pipe(
        map(json => Image.fromJson(json)),
        catchError((error: HttpErrorResponse) => of(error.error))
      );
  }

  getOriginalImage(value: string[]): Observable<any> {
    const url = `${this.baseUrl}/images/_bulk`;

    const body = {
      ids: value,
      thumb_name: 'original'
    };

    return this.httpClient.post(url, body)
      .pipe(catchError(error => throwError(error)));
  }

  getOriginalImageUrl(image: Image.Model): string {
    const reg = new RegExp('(_thumb_[\\d]+)|(\\?v=(\\d+(\\.\\d+)?))', 'g');
    const token = this.authService.getToken();
    const version = `v=${Math.random()}`;
    return `${this.baseUrl}/static/${image.path
      .split('?')[0]
      .replace(reg, '')}?token=${token}&${version}`;
  }

  private handleError(file: File) {
    const userMessage = `${file.name} upload failed.`;

    return (error: HttpErrorResponse) => {
      const message =
        error.error instanceof Error
          ? error.error.message
          : `server returned code ${error.status} with body "${error.error}"`;

      return of(userMessage);
    };
  }

  getMediaConfig(): Observable<any> {
    const endpoint = `${this.baseUrl}/configs`;

    return this.httpClient.get(endpoint);
  }

  adjustImage(imageId: string, formData: FormData) {
    const endpoint = `${this.baseUrl}/image/${imageId}/_adjust`;

    return this.httpClient.post(endpoint, formData).pipe(
      catchError((error: HttpErrorResponse) => {
        // if (error.status >= 400 && error.status < 500) {}
        const epicError = error.message;
        return throwError(new Error(epicError));
      })
    );
  }
}
