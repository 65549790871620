import { AdService } from '@app/models/premier-service.model';
import { Input } from '@angular/core';

export interface AdService {
  name: string;
  icon?: string;
  showIconAndDesc: boolean;
  typeId: number;
  price: number;
  description: string;
  buttonStyle: string;
  videoUrl: string;
  imageUrl: string;
  serviceName?: string;
  adId?: string;
  mainCat?: string;
  secCat?: string;
  inventoryId?: number;
  locationId?: number;
  fromWatchlist?: boolean;
  watchlistId?: boolean;
  disableSeeDetailsBtn?: boolean;
  newItem?: boolean;
}

export namespace PremierService {
  export class Model {
    availableSlotsCount: number;
    bannerMaxHeight: number;
    bannerMaxHeightMobile: number;
    bannerMaxWidth: number;
    bannerMaxWidthMobile: number;
    family: string;
    grouping: string;
    id: number;
    inWatchlist: boolean;
    isMoreLikeThis: number;
    name: string;
    numberOfSlots: number;
    price: number;
    principalCategory: string;
    principalCategoryId: number;
    productType: 'weekly' | 'monthly';
    runs: any[];
    secondaryCategory: string;
    secondaryCategoryId: number;
    typeId: number;
    watchlistId: number;
    description?: string;
    videoUrl?: string;
    imageUrl?: string;

    // in watchlist properties
    duration?: number;
    inventoryId?: number;
    lastAvailableOn?: Date;
    lastSoldOn?: Date;
    locationId?: number;
    usedSlots?: number;
  }

  export function fromJson(json): Model {
    return {
      availableSlotsCount: json.availableSlotsCount,
      bannerMaxHeight: json.banner_max_height,
      bannerMaxHeightMobile: json.banner_max_height_mobile,
      bannerMaxWidth: json.banner_max_width,
      bannerMaxWidthMobile: json.banner_max_width_mobile,
      family: json.family,
      grouping: json.grouping,
      id: json.id,
      inWatchlist: json.in_watchlist,
      isMoreLikeThis: json.is_more_like_this,
      name: json.name,
      numberOfSlots: json.number_of_slots,
      price: json.price,
      principalCategory: json.principal_category,
      principalCategoryId: json.principal_category_id,
      productType: json.product_type,
      runs: json.runs,
      secondaryCategory: json.secondary_category,
      secondaryCategoryId: json.secondary_category_id,
      typeId: json.type_id,
      watchlistId: json.watchlist_id,
      duration: json.duration,
      inventoryId: json.inventory_id,
      locationId: json.location_id,
      usedSlots: json.used_slots
    };
  }

  interface GroupedServiceTypes {
    marketLandingPage: ReadonlyArray<AdService>;
    categoryListingPage: ReadonlyArray<AdService>;
    whatsNewPage: ReadonlyArray<AdService>;
  }

  const marketLandingPage: ReadonlyArray<AdService> = [
    {
      name: 'diamond placement',
      icon: 'la la-diamond',
      showIconAndDesc: true,
      typeId: 48,
      price: 76,
      description: `Diamonds are a girl's best friend. Want EXCLUSIVE placement on the site? Top of the Market
                    after Stories section - Only 2 spots - Shows tagline - 7-day run, Tuesday to Tuesday`,
      buttonStyle: 'fill',
      videoUrl: 'svc_48.mp4',
      imageUrl: 'assets/service-posters/diamondplacement.jpg',
      serviceName: 'marketLandingPage'
    },
    {
      name: 'platinum placement',
      icon: 'qp',
      showIconAndDesc: true,
      buttonStyle: 'fill',
      typeId: 12,
      price: 30,
      description: `Platinum, the best fit for a queen.
        Want to be among the FIRST in your location? Market page - Only 16 spots  - 7-day run, Tuesday to Tuesday`,
      videoUrl: 'svc_12.mp4',
      imageUrl: 'assets/service-posters/platinumplacement.jpg',
      serviceName: 'marketLandingPage'
    },
    {
      name: 'worldwide featured entertainer',
      icon: 'W',
      showIconAndDesc: true,
      buttonStyle: 'fill',
      typeId: 16,
      price: 290,
      description: `This service is targeted to an International
        audience and is all about maximum exposure for any
        ad live on the Eros Guide network. This service is
        displayed on our main landing page (www.eros.com),
        and is currently limited to 12 positions displayed as
        thumbnails or animated thumbnails.`,
      videoUrl: 'svc_16.mp4',
      imageUrl: null,
      serviceName: 'marketLandingPage'
    },
    {
      name: 'girl of the day',
      showIconAndDesc: false,
      buttonStyle: 'outline',
      typeId: 49,
      price: 20,
      description: `Be the Chosen One. Want to be among the BEST
        for a day? Market page - Only 4 spots - Daily,
        midnight to midnight`,
      videoUrl: 'svc_49.mp4',
      imageUrl: 'assets/service-posters/girloftheday.jpg',
      serviceName: 'marketLandingPage'
    },
    {
      name: 'tile banner',
      showIconAndDesc: false,
      buttonStyle: 'outline',
      typeId: 2,
      price: 51,
      description: `Leave an everlasting mark. Want to be shown
        on your MARKET PAGE for a month? Market page - Only 2
        spots, can be combined - 30-day run`,
      videoUrl: 'svc_2.mp4',
      imageUrl: 'assets/service-posters/tilebanner.jpg',
      serviceName: 'marketLandingPage'
    },
    {
      name: 'ruby placement',
      icon: 'la la-ruby',
      showIconAndDesc: false,
      typeId: 53,
      price: 40,
      description: 'This gem stands for high-quality and uniqueness. Ruby invites you to join its excellence.\n' +
        'Market page - 4 exclusive spots - 7-day run, Tuesday to Tuesday',
      buttonStyle: 'fill',
      videoUrl: 'svc_53.mp4',
      imageUrl: '',
      serviceName: 'marketLandingPage',
      newItem: true
    }
    // {
    //   name: 'marquee banner',
    //   showIconAndDesc: false,
    //   buttonStyle: 'outline',
    //   typeId: 23,
    //   price: 60,
    //   description: `Be the rising sun. Want another chance to
    //     BE ON TOP of your market page?  Market page - Only 1
    //     spot - 7-day run, Tuesday to Tuesday`,
    //   videoUrl: 'svc_23.mp4',
    //   imageUrl: 'assets/service-posters/marqueebanner.jpg',
    //   serviceName: 'marketLandingPage'
    // }
  ];
  const categoryListingPage: ReadonlyArray<AdService> = [
    {
      name: 'gold placement',
      icon: 'G',
      showIconAndDesc: true,
      buttonStyle: 'fill',
      typeId: 27,
      price: 12,
      description: `Gold, the color of royalty. Want the BEST
        placement in one of our categories? Top of a Category  -
        Only 1 spot - 7-day run, Tuesday to Tuesday - Shows tagline`,
      videoUrl: 'svc_27.mp4',
      imageUrl: 'assets/service-posters/goldplacement.jpg',
      serviceName: 'categoryListingPage'
    },
    {
      name: 'gold placement available now',
      showIconAndDesc: false,
      buttonStyle: 'outline',
      typeId: 46,
      price: 15,
      description: `All gold, everything! A premium service that
        guarantees the first place in the available category.
        This weekly scheduled placement has only 1 spot!  Don't
        miss out.`,
      videoUrl: 'svc_46.mp4',
      imageUrl: 'assets/service-posters/goldplacementavailablenow.jpg',
      serviceName: 'categoryListingPage'
    },
    {
      name: 'silver placement',
      showIconAndDesc: false,
      buttonStyle: 'outline',
      typeId: 33,
      price: 10,
      description: `Silver reigns supreme. Want a boost that puts
        you ABOVE the rest? Category page - Only 16 spots - 30-day
        run`,
      videoUrl: 'svc_33.mp4',
      imageUrl: 'assets/service-posters/silverplacement.jpg',
      serviceName: 'categoryListingPage'
    },
    {
      name: 'category banner',
      showIconAndDesc: false,
      buttonStyle: 'outline',
      typeId: 3,
      price: 15,
      description: `Let your light shine bright. Show the world
        NOW that you are the BEST! Category page - Only 2 spots,
        can be combined - 30-day run`,
      videoUrl: 'svc_3.mp4',
      imageUrl: 'assets/service-posters/categorybanner.jpg',
      serviceName: 'categoryListingPage'
    }
  ];
  const whatsNewPage: ReadonlyArray<AdService> = [
    {
      name: 'what\'s new featured entertainer',
      icon: 'F',
      showIconAndDesc: true,
      buttonStyle: 'fill',
      typeId: 19,
      price: 60,
      description: `Shoot for the stars. Want everyone to know
        about the NEW STAR in town? What's New page - Only 2 spots,
        can be combined - 7-day run, Tuesday to Tuesday - Shows
        tagline - Top of your market’s What's New page`,
      videoUrl: 'svc_19.mp4',
      imageUrl: 'assets/service-posters/whatsnewfeaturedentertainer.jpg',
      serviceName: 'whatsNewPage'
    },
    {
      name: 'what\'s new top banner',
      showIconAndDesc: false,
      buttonStyle: 'fill',
      typeId: 51,
      price: 5,
      description: `There’s a new girl in town. Want to show
        everyone that you are NEW on the site? What's New page -
        Only 4 spots, can be combined - 2 on the top, 2 on the
        bottom - 7-day run, Tuesday to Tuesday`,
      videoUrl: 'svc_51.mp4',
      imageUrl: 'assets/service-posters/whatsnewbanner.jpg',
      serviceName: 'whatsNewPage'
    },
    {
      name: 'what\'s new bottom banner',
      showIconAndDesc: false,
      buttonStyle: 'fill',
      typeId: 52,
      price: 5,
      description: `There’s a new girl in town. Want to show
        everyone that you are NEW on the site? What's New page -
        Only 4 spots, can be combined - 2 on the top, 2 on the
        bottom - 7-day run, Tuesday to Tuesday`,
      videoUrl: 'svc_52.mp4',
      imageUrl: 'assets/service-posters/whatsnewbanner.jpg',
      serviceName: 'whatsNewPage'
    }
  ];

  export function servicesFactory(): GroupedServiceTypes {
    return {
      marketLandingPage,
      categoryListingPage,
      whatsNewPage
    };
  }

  export function getById(id: number): AdService {
    return [...marketLandingPage, ...categoryListingPage, ...whatsNewPage].find(
      service => {
        return service.typeId === id;
      }
    );
  }
}

// export namespace ServiceDescription {
//   export class Model {
//     name: string;
//     showIconAndDesc: boolean;
//     buttonStyle: string;
//     description: string;
//     videoUrl: string;
//     imageUrl: string;
//     typeId: number;
//     price: number;
//     icon?: string;
//     serviceName?: string;
//   }
// }

export namespace ServiceQuery {
  export interface Model {
    mainCategories?: string[];
    specialtyCategories?: string[];
    principalCategoryIds?: number[];
    secondaryCategoryIds?: number[];
    locationCategories?: string[];
    hairColor?: string;
    availableTo?: string;
    availableFor?: string[];
    gender?: string;
    isVip?: 1 | 0;
    ethnicities?: string[];
    affiliation?: string;
    cupSize?: string;
    isVisiting?: 1 | 0;
    isVideo?: 1 | 0;
    isAuthenticated?: 1 | 0;
    isAvailableNow?: 1 | 0;
    family?: string;
    serviceTypeIds?: number[];
    locationId?: number[];
    locationIds?: number[];
  }

  export function buildQueries(q: Model) {
    const o = {
      main_categories: q.mainCategories,
      specialty_categories: q.specialtyCategories,
      location_categories: q.locationCategories,
      principal_category_ids: q.principalCategoryIds,
      secondary_category_ids: q.secondaryCategoryIds,
      hair_color: q.hairColor,
      available_to: q.availableTo,
      available_for: q.availableFor,
      gender: q.gender,
      is_vip: q.isVip,
      ethnicities: q.ethnicities,
      affiliation: q.affiliation,
      cup_size: q.cupSize,
      is_visiting: q.isVisiting,
      is_video: q.isVideo,
      is_authenticated: q.isAuthenticated,
      is_available_now: q.isAvailableNow,
      family: q.family,
      service_type_ids: q.serviceTypeIds,
      location_id: q.locationId,
      location_ids: q.locationIds
    };

    return Object.keys(o).reduce((acc, v) => {
      // if (v === 'location_id') {
      //   acc[v] = o.location_id || 1000;
      // } else {
      //   if (o[v]) {
      //     acc[v] = o[v];
      //   }
      // }
      acc[v] = o[v];
      return acc;
    }, {});
  }
}
