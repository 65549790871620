import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '@env/environment';
import { Rates } from '@app/models/rates.model';
import { PremiumAd, Price, Profile } from '@app/models';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class PremiumAdService {
  apiRoot: string;
  priceUrl: string;
  exchangeRateUrl: string;
  private readonly baseUrl: string = `${environment.apiUrl}/premium-ad`;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.apiRoot = 'inventory?location_id';
    this.priceUrl = 'price/';
    this.exchangeRateUrl = 'payment/exchange-rate';
  }

  private _buildPriceRequestParams(priceQuery: Price.QueryModel): HttpParams {
    let params = new HttpParams();

    params = params.set('location_id', priceQuery.locationId.toString());
    params = params.set('inventory_id', priceQuery.inventoryId.toString());
    params = params.set('is_vip', priceQuery.isVip ? '1' : '0');
    params = params.set('is_animated_thumbnail', priceQuery.isAnimatedThumbnail ? '1' : '0');
    params = params.set('is_group_ad', priceQuery.isGroupAd ? '1' : '0');
    params = params.set('is_video', priceQuery.isVideo ? '1' : '0');
    params = params.set('account_id', this.authService.getAuthUserSnapshot().id.toString());

    params = params.set('is_bdsm', priceQuery.isBdsm ? '1' : '0');

    priceQuery.mainCategories.forEach(item => {
      params = params.append('main_categories[]', item);
    });

    priceQuery.specialtyCategories.forEach(item => {
      params = params.append('specialty_categories[]', item);
    });

    if (priceQuery.duration) {
      params = params.set('duration', priceQuery.duration.toString());
    }

    if (priceQuery.adId) {
      params = params.set('ad_id', priceQuery.adId);
    }

    return params;
  }

  getInventoryById(id: number) {
    const url = `${this.baseUrl}/${this.apiRoot}=${id}`;

    return this.httpClient.get<{
      standard: Array<{id: number, duration: number}>,
      visiting: Array<{id: number, duration: number}>
    }>(url)
      .pipe(
        catchError((error: HttpErrorResponse) => throwError(new Error(error.error)))
      );
  }

  getExchangeRates() {
    const url = `${environment.apiUrl}/${this.exchangeRateUrl}`;
    return this.httpClient
      .get<{[key in Rates]: number}>(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(new Error(error.error))
        )
      );
  }

  getPrices(
    inventory_id: number,
    location_id: number,
    categories: any,
    is_vip: number = 0
  ) {
    const url = `${this.baseUrl}/price`;
    const options = {
      params: new HttpParams()
        .set('inventory_id', inventory_id.toString())
        .set('location_id', location_id.toString())
        .set('main_categories[]', categories.toString())
        .set('is_vip', is_vip.toString())
    };
    return this.httpClient
      .get<Price.ResultModel>(url, options)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(new Error(error.error))
        )
      );
  }

  findPrices(priceQuery: Price.QueryModel) {
    const endpoint = `${this.baseUrl}/price`;
    const params = this._buildPriceRequestParams(priceQuery);

    return this.httpClient.get<Price.ResultModel>(endpoint, {params});
  }

  findPricesFull(priceQuery: Partial<Price.QueryModel>) {
    const endpoint = `${this.baseUrl}/price/read_full`;

    // if (environment.production === false && priceQuery.duration % 24 !== 0) {
    //   throwError(new Error(`priceQuery.duration % 24 !== 0 (${priceQuery.duration % 24})`));
    //   return;
    // }

    let params = new HttpParams()
      .set('account_id', this.authService.getAuthUserSnapshot().id.toString())
      .set('ad_id', priceQuery.adId)
      .set('location_id', priceQuery.locationId.toString())
      .set('inventory_id', priceQuery.inventoryId.toString())
      .set('is_bdsm', priceQuery.isBdsm.toString());

    if (priceQuery.duration) {
      params = params.set('duration', priceQuery.duration.toString());
    }

    return this.httpClient.get<Price.ResultModel>(endpoint, {params});
  }

}


