// Angular specific
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { retryWhen, mergeMap, delay, map, catchError, tap } from 'rxjs/operators';
import { EMPTY, Subscription, throwError } from 'rxjs';

// App specific
import { environment } from '@env/environment';
import { WINDOW } from '@app/_shared/tokens';
import { CommerceService } from '@app/_api';

@Component({
  selector: 'sc-recommended-payment',
  template: `
    <div class="recommended-payment-try">
      <h1>Processing...</h1>
      <br />
      <p>
        Your payment has been successful. It may take some time for our system
        to process your order. Your services may still be in your shopping cart.
        Please do not remove them. After your payment is processed the shopping
        cart will be empty.
      </p>
    </div>
  `,
  styleUrls: ['./recommended.component.scss'],
})
export class RecommendedComponent implements OnInit, OnDestroy {
  private _queryParamsSub = Subscription.EMPTY;

  constructor(
    private router: Router,
    private commerceService: CommerceService,
    private route: ActivatedRoute,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit() {
    /**
     * @description whats going on :)
     *  window.parent.postMessage will be consumed by CheckoutPaymentComponent
     *  this.window.addEventListener('message', cb)
     * */
    this._queryParamsSub = this.route.queryParamMap
      .pipe(
        mergeMap((queryParamMap) => {
          if (queryParamMap.get('status') === 'failed') {
            this.window.parent.postMessage(
              { status: queryParamMap.get('status') },
              environment.origin
            );

            return EMPTY;
          }

          return this.commerceService.getOrderByRef(queryParamMap.get('ref_id'))
            .pipe(
              tap((_) =>
                this.window.parent.postMessage(
                  {
                    refId: queryParamMap.get('ref_id'),
                    status: queryParamMap.get('status'),
                    gateway: queryParamMap.get('gateway'),
                    amount: queryParamMap.get('amount'),
                  },
                  environment.origin
                )
              ),
              catchError((err) => throwError({ tryAgain: true })),
              retryWhen((errors) => {
                return errors.pipe(
                  delay(5000),
                  map((err) => !!err.tryAgain)
                );
              })
            );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._queryParamsSub.unsubscribe();
  }
}
