import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

interface ScriptModel {
  name: string;
  loaded?: boolean;
  type: 'content' | 'src';
  content?: string;
  src?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private scripts: ScriptModel[] = [];
  constructor() { }

  hasScript(scriptName: string) {
    return this.scripts.some(item => item.name === scriptName);
  }

  load(script: ScriptModel): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.name === script.name);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      }
      else {
        // Add the script
        this.scripts = [...this.scripts, script];
        const rootElem = document.getElementsByTagName('body')[0];

        // create script element
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';

        switch (script.type) {
          case 'content': {
            const inlineScript = document.createTextNode(script.content);
            scriptElement.appendChild(inlineScript);
            rootElem.appendChild(scriptElement);
            script.loaded = true;
            observer.next(script);
            observer.complete();
            break;
          }

          case 'src': {
            // Load the script
            scriptElement.src = script.src;

            scriptElement.onload = () => {
              script.loaded = true;
              observer.next(script);
              observer.complete();
            };

            scriptElement.onerror = (error: any) => {
              observer.error('Couldn\'t load script ' + script.src);
            };
            rootElem.appendChild(scriptElement);
            break;
          }
        }
      }
    });
  }
}
