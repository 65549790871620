import { environment } from '@env/environment';

export namespace Image {
  export interface Model {
    status: 'pending' | 'finished';
    processStatus: 'pending' | 'finished' | 'failed';
    imageId: string;
    hash: string;
    ext: 'jpg' | 'jpeg' | 'png';
    thumbName: ThumbName;
    isAdjusted?: boolean;
    filename: string;
    width: number;
    height: number;
    path: string;
    fullPath: string;
    type: 'resize' | 'rescrop';
    dateCreated: Date;
    cropArgs: {
      x: string;
      y: string;
    } | null;
    url: string;
  }

  export type ThumbName = 'profile' | 'thumb_558' | 'thumb_248' | 'thumb_186' | 'thumb_60' | 'thumb_265';

  export type Degree =
    | 0
    | 90
    | 180
    | 270
    | 360
    | -90
    | -180
    | -270
    | -360
    | null;

  export interface AdjustModel {
    resizeThumb: ThumbName;
    coordinates: {
      x: number;
      y: number;
    };
    scale: number;
    degree: Degree;
  }

  export function fromJson(json: any): Model {
    return {
      status: json.status,
      imageId: json.image_id,
      hash: json.hash,
      ext: json.ext,
      thumbName: json.thumb_name,
      isAdjusted: json.is_adjusted,
      filename: json.filename,
      processStatus: 'finished',
      width: json.width,
      height: json.height,
      path: json.path,
      fullPath: `${environment.mediaUrl}/${json.path}`,
      type: json.type,
      dateCreated: new Date(json.date_created),
      cropArgs: json.crop_args ? json.crop_args : null,
      url: json.url
    };
  }

  export function empty(): Model {
    return {
      status: null,
      imageId: null,
      hash: '#',
      ext: 'png',
      thumbName: null,
      isAdjusted: null,
      filename: 'default',
      processStatus: 'finished',
      width: null,
      height: null,
      path: 'assets/images/default_177x236.png',
      fullPath: 'assets/images/default_177x236.png',
      type: null,
      dateCreated: new Date(),
      cropArgs: null,
      url: null
    };
  }

  export function srcFrom(image: Image.Model): string {
    return `${environment.mediaUrl}/${image.path}`;
  }
}
