import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { AuthService } from '@app/_api';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

// type Config = 'showSidebar' | 'showHeader' | 'showFooter';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public layoutConfig = new Map<
    'showSidebar' | 'showSideNav' | 'showHeader' | 'showFooter',
    boolean
  >()
    .set('showSidebar', false)
    .set('showSideNav', false)
    .set('showHeader', true)
    .set('showFooter', true);

  constructor(private authService: AuthService, private router: Router) {
    combineLatest([this.authService.isLoggedIn$, this.router.events])
      .pipe(
        tap(([isLogged, events]) => {
          this.checkUrlForLayout(isLogged.valueOf());
        })
      )
      .subscribe();
  }

  checkUrlForLayout(isLogged: boolean) {
    const myUrl = this.router.url.includes('/my');
    this.layoutConfig.set('showSideNav', false);
    if (myUrl) {
      this.layoutConfig.set('showHeader', false);
      this.layoutConfig.set('showFooter', false);
      this.layoutConfig.set('showSidebar', true);
    } else {
      this.layoutConfig.set('showHeader', true);
      this.layoutConfig.set('showFooter', true);
      this.layoutConfig.set('showSidebar', false);
    }
  }
}
