import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MinTwoDigitsPipe } from './min-two-digits.pipe';
import { BytesPipe } from './bytes.pipe';
import { SafePipe } from './safe.pipe';
import { ReplacePipe } from './replace.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    BytesPipe,
    MinTwoDigitsPipe,
    ReplacePipe
  ],
  declarations: [
    SafePipe,
    BytesPipe,
    MinTwoDigitsPipe,
    ReplacePipe,
  ],
})
export class PipesModule { }
