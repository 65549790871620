import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { AnnouncementDialogComponent } from './announcement-dialog.component';

@NgModule({
  declarations: [
    AnnouncementDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  entryComponents: [
    AnnouncementDialogComponent
  ]
})
export class AnnouncementDialogModule {
  constructor(@Optional() @SkipSelf() parentModule?: AnnouncementDialogModule) {
    if (parentModule) {
      throw new Error('InformDialogModule is already loaded. Import it in the AppModule/CoreModule only');
    }
  }
}
