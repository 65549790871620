import { Injectable } from '@angular/core';
import { Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { AuthService } from '@app/_api';

@Injectable({
  providedIn: 'root'
})
export class CanActivateWhenUnauthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user$.pipe(
      take(1),
      map((authUser) => {
        if (authUser) {
          return this.router.createUrlTree(['/my'], { replaceUrl: true });
        }
        return true;
      })
    );
  }
}
