import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AuthService } from '@app/_api';
import { AA_VERSION } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class AaVersionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user$.pipe(
      filter((authUser) => Boolean(authUser)),
      take(1),
      map((authUser) => {
        if (authUser.aaVersion === AA_VERSION) {
          return true;
        }
        return this.router.createUrlTree(['/agreement']);
      }),
    );
  }
}
