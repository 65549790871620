import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@app/_api';
import { LocalStorage, SessionStorage } from '@app/_services';

interface IImpersonateQueryParams {
  token: string;
  account_id: string;
}

@Component({
  selector: 'sc-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss']
})
export class ImpersonationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
  ) {}

  ngOnInit() {
    const queryParamMap = this.route.snapshot.queryParamMap;
    const accountId = queryParamMap.get('account_id');
    const token = queryParamMap.get('token');

    // TODO: move code from ifd statement into impersonation guard
    if (!accountId || !token) {
      this.router.navigate(['/home', { replaceUrl: true }]).then(() => {
        console.log('Something went wrong. Trying to [Impersonate as moderator]');
      });
    } else {
      this.impersonate(parseInt(accountId), token);
    }
  }

  private impersonate(accountId: number, token: string): void {
    this.authService.removeTokens();
    this.authService.removeDeviceHash();

    this.sessionStorage.setItem('userId', accountId);
    this.localStorage.setItem('usertoken', token);

    this.authService.getAuthUser().subscribe((authUser) => {
      if (authUser) {
        this.authService.makeUserAuthenticated(authUser);

        this.router.navigate(['/my'], { replaceUrl: true }).then(() => {
          console.log('[Impersonated as moderator]');
        });
      }
      else {
        this.authService.makeUserUnauthenticated();

        this.router.navigate(['/home'], { replaceUrl: true }).then(() => {
          console.log('Something went wrong when [Impersonated as moderator]');
        });
      }
    });
  }
}
