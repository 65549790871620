import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

export type PanelClass = 'panel-danger' | 'panel-success' | 'panel-info' | 'panel-warning';

export const initialConfigs: MatSnackBarConfig = {
  duration: 4000,
  verticalPosition: 'top',
  horizontalPosition: 'center'
};

@Injectable({
  providedIn: 'root'
})
export class ToastyService {

  constructor(private _snackBar: MatSnackBar) { }

  error(message: string, action: string = null, configs: MatSnackBarConfig = {}) {
    return this.openSnackBar(message, 'panel-danger', action, configs);
  }

  success(message: string, action: string = null, configs: MatSnackBarConfig = {}) {
    return this.openSnackBar(message, 'panel-success', action, configs);
  }

  info(message: string, action: string = null, configs: MatSnackBarConfig = {}) {
    return this.openSnackBar(message, 'panel-info', action, configs);
  }

  warning(message: string, action: string = null, configs: MatSnackBarConfig = {}) {
    return this.openSnackBar(message, 'panel-warning', action, configs);
  }

  private openSnackBar(
    msg: string,
    panelClass: PanelClass,
    action = null,
    configs: MatSnackBarConfig
  ) {
    return this._snackBar.open(msg, action, {
      ...initialConfigs,
      ...configs,
      panelClass
    });
  }
}
