export namespace Cart {
  export class Model {
    amount: number;
    bump: number;
    manualService: number;
    premiumAd: number;
    premiumService: number;
    totalCount: number;
  }

  export function fromJson(json: any): Model {
    return {
      amount: json.amount,
      bump: json.bump,
      manualService: json.manual_service,
      premiumAd: json.premium_ad,
      premiumService: json.premium_service,
      totalCount: json.total_count
    };
  }
}

export namespace ShoppingCart {
  export class Model {
    accountId: number;
    amount: number;
    bumps: any[];
    currency: string;
    dateCreated: Date;
    id: number;
    isSystem: number;
    manualServices: any[];
    premiumAds: any[];
    premiumServices: any[];
    systemAmount: number;
  }

  export function fromJson(json: any): Model {
    return {
      accountId: json.account_id,
      amount: json.amount,
      bumps: json.bumps,
      currency: json.currency,
      dateCreated: new Date(json.date_created),
      id: json.id,
      isSystem: json.is_system,
      manualServices: json.manual_services,
      premiumAds: json.premium_ads,
      premiumServices: json.premium_services,
      systemAmount: json.system_amount
    };
  }
}

export namespace Balance {
  export class Model {
    balance: number;
    currency: string;
  }
}
