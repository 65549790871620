import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@env/environment';
import { EnvironmentTarget } from '@env/target';
import { LocalStorage, SessionStorage } from '@app/_services/storage.service';
import { User } from '@app/models';
import { ZendeskHelperService } from '@app/_services/zendesk-helper.service';

const jwtHelper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly baseUrl: string = `${environment.apiUrl}/account`;
  private readonly userState = new BehaviorSubject<User.Model>(null);
  private readonly impersonatedModeratorState = new BehaviorSubject<User.Model>(null);
  private readonly isLoggedInState = new BehaviorSubject<IsLoggedInValue>(new IsLoggedInValue(false, 'INIT'));
  private readonly currencyState = new BehaviorSubject<string>(null);
  isImpersonated = false;

  decoded: User.Model = null;
  remember: Date = null;

  user$ = this.userState.asObservable();

  readonly impersonatedModerator$ = this.impersonatedModeratorState.asObservable();

  readonly isLoggedIn$ = this.isLoggedInState.asObservable();

  readonly currency$ = this.currencyState.asObservable();

  readonly isLoggedInAs$$ = (roles: string[]) =>
    combineLatest([this.user$, this.impersonatedModerator$]).pipe(
      map(([authUser, authModerator]) => roles.includes((authModerator || authUser).role)),
      catchError(() => of(false))
    )

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
  ) {
    this.impersonatedModerator$.subscribe((authModerator) => {
      this.isImpersonated = !!authModerator;
    });
  }

  setUserState(account: User.Model) {
    this.userState.next(account);
    this.user$ = this.userState.asObservable();
  }

  getAuthUser(): Observable<User.Model | null> {
    const decoded = this.decodeToken(this.getToken());

    if (!decoded) {
      return of(null);
    }

    // Impersonated account
    if (decoded.role !== 'customer') {
      // impersonated account id
      const userId = this.sessionStorage.getItem<string>('userId');
      return userId ? this.getAccountByWithPermissionChange(userId) : of(null);
    } else {
      // return of(User.fromJson(decoded));
      return this.getAccount(decoded.id);
    }
  }

  getAuthUserSnapshot(): User.Model | null {
    return this.userState.getValue();
  }

  makeUserAuthenticated(authUser: User.Model): void {
    this.userState.next(authUser);
    this.isLoggedInState.next(new IsLoggedInValue(true, 'STANDALONE'));
    this.currencyState.next(authUser.currency);

    if (this.decoded.role !== 'customer') {
      this.impersonatedModeratorState.next(this.decoded);
    }
  }

  makeUserUnauthenticated(): void {
    this.userState.next(null);
    this.impersonatedModeratorState.next(null);
    this.isLoggedInState.next(new IsLoggedInValue(false, 'STANDALONE'));
    this.currencyState.next(null);
    this.removeTokens();
  }

  setRemember(): void {
    this.localStorage.setItem('_remember', new Date().toString());
    this.remember = new Date(this.localStorage.getItem('_remember'));
  }

  logIn(authUser: User.Model, jwtToken: string): void {
    this.localStorage.setItem('usertoken', jwtToken);
    this.decodeToken(this.getToken());
    this.userState.next(authUser);
    this.isLoggedInState.next(new IsLoggedInValue(true, 'USER_ACTION'));
    this.currencyState.next(authUser.currency);
  }

  logout(removeDeviceHash: boolean = false) {
    if (removeDeviceHash) {
      this.removeDeviceHash();
    }

    this.removeTokens();

    this.userState.next(null);
    this.isLoggedInState.next(new IsLoggedInValue(false, 'USER_ACTION'));
    this.currencyState.next(null);
    ZendeskHelperService.hideWidget();

    window.location.href = '/home';
  }

  setToken(token: string): void {
    this.localStorage.setItem('usertoken', token);
  }

  getToken(): string | null {
    const t = this.cookieService.get('usertoken');

    // migrate from old implementation
    if (t) {
      this.localStorage.setItem('usertoken', t);
      this.cookieService.delete('usertoken', '/my');
      this.cookieService.delete('usertoken', '/');
    }

    return this.localStorage.getItem('usertoken');
  }

  getDecodedToken(): User.Model {
    return User.fromJson(this.decoded);
  }

  decodeToken(token): any {
    if (!token) {
      return null;
    }

    try {
      this.decoded = jwtHelper.decodeToken(token);
      return this.decoded;
    } catch (e) {
      return null;
    }

    // if (!decoded || jwtHelper.isTokenExpired(token)) {
    //   return null;
    // }
  }

  hasPermission(permission: string) {
    if (!this.decoded) {
      return false;
    }

    return (
      this.decoded.role === 'admin' ||
      !!this.decoded.permissions.find((perm) => {
        // replacing .* and .# to check if account has global permission
        const globalPermission = permission.replace('.#', '').replace('.*', '');

        const globalRegexp = AuthService.makeRegExpFromPermission(globalPermission);
        const regexp = AuthService.makeRegExpFromPermission(permission);

        if (perm.match(globalRegexp) !== null || perm.match(regexp) !== null) {
          return true;
        }
      })
    );
  }

  removeTokens() {
    this.localStorage.removeItem('usertoken');
    this.localStorage.removeItem('_remember');
    this.sessionStorage.removeItem('userId');
  }

  setDeviceHash(hash: string, jwtToken: string): void {
    const expires: Date = jwtHelper.getTokenExpirationDate(jwtToken);
    const path = '/';
    const domain = (environment.target === EnvironmentTarget.Dev ? null : `.${environment.interiorHost}`);
    const secure = (environment.target !== EnvironmentTarget.Dev);
    // const sameSite = 'None'; // 'Lax' | 'None' | 'Strict'

    if (this.cookieService.check('d-id')) {
      this.cookieService.delete('d-id', path, domain);
    }

    this.cookieService.set('d-id', hash, expires, path, domain, secure);
  }

  getDeviceHash(): string {
    return this.cookieService.get('d-id');
  }

  hasDeviceHash(): boolean {
    return this.cookieService.check('d-id');
  }

  removeDeviceHash(): void {
    const path = '/';
    const domain = (environment.target === EnvironmentTarget.Dev ? null : `.${environment.interiorHost}`);
    const secure = (environment.target !== EnvironmentTarget.Dev);

    this.cookieService.delete('d-id', path, domain, secure);
  }

  private getAccount(accountId: number): Observable<User.Model> {
    const url = `${this.baseUrl}/${accountId}`;

    const params = {
      ipTrack: 'true'
    }

    return this.httpClient.get(url, { params }).pipe(
      map((json) => User.fromJson(json)),
      catchError((err: HttpErrorResponse) => of(null))
    );
  }

  private getAccountByWithPermissionChange(accountId: string): Observable<User.Model> {
    const url = `${this.baseUrl}/${accountId}`;

    return this.httpClient.get(url).pipe(
      map((json) => {
        if (this.decoded.role === 'admin') {
          this.decoded.permissions = ['*'];
        }
        json['permissions'] = this.decoded.permissions;

        return User.fromJson(json);
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.message);
      })
    );
  }

  private static makeRegExpFromPermission(permission: string): RegExp {
    permission = '^' + permission;
    permission
      .replace(/\./g, '\\.')
      .replace(/\*/g, '([^.]+)')
      .replace(/\#/g, '(.+)');
    permission += '$';

    return new RegExp(permission);
  }
}

export class IsLoggedInValue {
  constructor(
    private value: boolean,
    private reason: 'INIT' | 'USER_ACTION' | 'STANDALONE'
  ) {}

  valueOf() {
    return Boolean(this.value);
  }

  // todo: Ashot
  // toString() {
  //   return `${Boolean(this.value)}`;
  // }

  getReason(): 'INIT' | 'USER_ACTION' | 'STANDALONE' {
    return this.reason;
  }
}
