import { Component, Inject, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AnnouncementDialogConfig } from './announcement-dialog.service';

@Component({
  selector: 'sc-announcement-dialog',
  templateUrl: './announcement-dialog.component.html',
  styleUrls: ['./announcement-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnnouncementDialogComponent implements OnInit {
  title!: string;
  text!: string | null;
  textTemplateRef!: TemplateRef<any> | null;
  confirmBtnText!: string;

  isStory: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AnnouncementDialogConfig,
    private dialogRef: MatDialogRef<AnnouncementDialogComponent, void>
  ) {
    this.title = data.title;
    this.isStory = data.isStory;

    if (data.textOrTemplateRef instanceof TemplateRef) {
      this.text = null;
      this.textTemplateRef = data.textOrTemplateRef;
    } else {
      this.textTemplateRef = null;
      this.text = data.textOrTemplateRef;
    }

    this.confirmBtnText = data.confirmBtnText || 'OK';
  }

  ngOnInit() {}

  onSubmit() {
    this.dialogRef.close();
  }
}
