export class DeviceModel {
  id: number;
  accountId: number;
  deviceHash: string;
  tokenHash: string;
  deviceName: string;
  deviceOs: DeviceOperationSystem | null;
  deviceType: DeviceType | null;
  deviceStatus: DeviceStatus;
  deviceVerificationCode: string | null;
  tokenDateCreated: Date;
  deviceDateCreated: Date;
  tokenDateLastActivity: Date | null;
  deviceDateExpiration: Date;

  static fromJSON(json: any): DeviceModel {
    return Object.assign(DeviceModel.empty(), {
      id: json.id,
      accountId: json.account_id,
      deviceHash: json.device_hash,
      tokenHash: json.token_hash,
      deviceName: json.device_name,
      deviceOs: json.device_os || null,
      deviceType: json.device_type,
      deviceStatus: json.device_status,
      deviceVerificationCode: json.device_verification_code || null,
      tokenDateCreated: new Date(json.token_date_created),
      deviceDateCreated: new Date(json.device_date_created),
      tokenDateLastActivity: json.token_date_last_activity ? new Date(json.token_date_last_activity) : null,
      deviceDateExpiration: new Date(json.device_date_expiration),
    });
  }

  static empty(): DeviceModel {
    return new DeviceModel();
  }
}

export enum DeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Tablet = 'tablet',
}

export enum DeviceOperationSystem {
  iOS = 'iOS',
  Android = 'Android',
  Windows = 'Windows',
  Others = 'Others',
}

export enum DeviceStatus {
  NotVerified = 'not_verified',
  Verified = 'verified',
  VerifiedNotTrusted = 'verified_not_trusted',
}
