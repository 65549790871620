import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, map, tap, shareReplay, refCount } from 'rxjs/operators';

import { MapOnlyNameOfKeys, Profile, Commerce } from '@app/models/configs.model';

interface Configs {
  profileFormData: Profile.FormData.Model;
  profileConfigs: Profile.Configs.Model;
  mediaConfigs: {[key: string]: any};
  account: {[key: string]: any};
  commerce: Commerce.Options.Model;
}

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  constructor(
    private httpClient: HttpClient
  ) {}

  private readonly baseUrl: string = `${environment.apiUrl}`;

  /**
   * @description Cached configs
   * */
  private formData$: Observable<Configs>;

  getProfileFormData() {
    const url = `${this.baseUrl}/escort-profile/form-data`;
    return this.httpClient.get(url).pipe(
      map((json: MapOnlyNameOfKeys<Profile.FormData.Model>) => Profile.FormData.fromJson(json))
    );
  }

  getProfileConfigs() {
    const url = `${this.baseUrl}/escort-profile/configs`;
    return this.httpClient.get(url).pipe(
      map((json: MapOnlyNameOfKeys<Profile.Configs.Model>) =>
        Profile.Configs.fromJson(json)
      )
    );
  }

  getPromoBanner() {
    const url = `${this.baseUrl}/promo-banner/public`;
    return this.httpClient.get<Array<any>>(url);
  }

  getMediaConfigs() {
    const url = `${this.baseUrl}/media/configs`;
    return this.httpClient.get<{[key: string]: any}>(url);
  }

  getAccountFormData() {
    const url = `${this.baseUrl}/account/form-data`;
    return this.httpClient.get<{[key: string]: any}>(url);
  }

  getCommerceFormData() {
    const url = `${this.baseUrl}/commerce/options`;
    return this.httpClient.get<Commerce.Options.Model>(url);
  }

  get formData(): Observable<Configs> {
    if (this.formData$) {
      return this.formData$;
    }

    return (
      this.formData$ = forkJoin({
        profileFormData: this.getProfileFormData(),
        mediaConfigs: this.getMediaConfigs(),
        account: this.getAccountFormData(),
        commerce: this.getCommerceFormData()
      }).pipe(
        map(res => ({
          ...res,
          profileConfigs: res.profileFormData.configs
        })),
        shareReplay(1)
      )
    );
  }
}
