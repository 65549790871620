import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  catchError,
  shareReplay,
  refCount,
  mergeMap,
  map, tap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeographyService {
  private readonly baseUrl: string = `${environment.apiUrl}/geography`;
  geographyServiceRootNodeId: number;
  private _leaves$: Observable<any[]>;

  constructor(private httpClient: HttpClient) {
    this.geographyServiceRootNodeId = 1;
  }

  getChildrenById(id: number): Observable<any> {
    const url = `${this.baseUrl}/${id}/children`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(new Error(error.error))
        )
      );
  }

  getLocationPathById(id: number): Observable<any> {
    const url = `${this.baseUrl}/${id}/path`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(new Error(error.error))
        )
      );
  }

  findLeaves() {
    if (!this._leaves$) {
      this._leaves$ = this.httpClient.get<any[]>(`${this.baseUrl}/leaves`).pipe(
        mergeMap(markets =>
          this.getPathsByIds(markets.map(n => n.id)).pipe(
            map(paths => {
              return markets.map(n => {
                const path = paths[n.id];

                if (n.alias_of) {
                  // n.id = n.alias_of;
                  n.alias_of_entity = markets.find(m => m.id === n.alias_of);
                }

                if (!path) {
                  n.path = [n];
                } else {
                  n.path = path;
                }

                n.pathFull = path;
                n.path = n.path.map(item => item.name).reverse().join(', ');
                n.text = n.path;

                // disable - Texas state, Kansas city
                // n.disabled = (
                //   n.pathFull.some(item => item.type === 'state' && item.id === 83) ||
                //   n.id === 16
                // );
                n.disabled = false;

                return n;
              }, []);
            })
          )
        ),
        shareReplay(1)
      );
    }

    return this._leaves$;
  }

  getPathById(id: number) {
    return this.httpClient.get<any>(`${this.baseUrl}/${id}/path`);
  }

  getPathsByIds(node_ids: number[]) {
    return this.httpClient.post<any>(`${this.baseUrl}/paths/_bulk`, {
      node_ids
    });
  }

  getAllCountries() {
    return this.httpClient.get<any>(`${this.baseUrl}/countries/_bulk`);
  }
}
