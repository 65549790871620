import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from '@app/_api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly disallowedRoutes = [
    `${environment.apiUrl}/account/token`
  ];

  // private readonly deviceVerificationCodeUrls = [
  //   `${environment.apiUrl}/account/token/verification-code/check`,
  //   `${environment.apiUrl}/account/token/verification-code/resend`,
  // ];

  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();
    const isAllowedReqUrl = !this.disallowedRoutes.includes(req.url);

    const deviceHash = this.authService.getDeviceHash();

    if (deviceHash) {
      req = req.clone({
        setHeaders: {
          'Cookie-D-Id': deviceHash
        }
      });
    }

    if (authToken && isAllowedReqUrl) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${authToken}`,
        }
      });
    }

    return next.handle(req).pipe(
      catchError((errResponse: HttpErrorResponse) => {
        if ([401, 403].includes(errResponse.status)) {
          this.authService.logout(true);
        }

        return throwError(errResponse);
      })
    );
  }
}
