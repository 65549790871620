import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Options } from './confirm-dialog.service';

@Component({
  selector: 'sc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Output() submit: EventEmitter<any> = new EventEmitter();

  viewLoading = false;
  stayAfterSubmit = !!this.data.stayAfterSubmit;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Options
  ) {}

  ngOnInit() {}

  cancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.viewLoading = true;
    this.submit.emit(true);
    if (this.stayAfterSubmit) {
      return;
    }

    this.dialogRef.close(true);
  }

  get cancelBtnText() {
    return this.data.cancelButtonText || 'Cancel';
  }

  get confirmBtnText() {
    if (this.data.confirmButtonText) {
      return this.data.confirmButtonText;
    }

    switch (this.data.type) {
      case 'delete': {
        return 'Delete';
      }
      case 'create': {
        return 'Save';
      }
      case 'update': {
        return 'Update';
      }

      default: {
        return 'Ok';
      }
    }
  }
}
