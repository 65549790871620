import { APP_INITIALIZER, FactoryProvider, Injector } from '@angular/core';
import { Location } from '@angular/common';

import { AuthService } from '@app/_api';

export function authUserInitializerFactory(injector: Injector): () => Promise<void> {
  return () => {
    const authService = injector.get(AuthService);
    const location = injector.get(Location);

    if (location.path(false).startsWith('/inject')) {
      return Promise.resolve();
    }

    return authService.getAuthUser().toPromise()
      .then((authUser) => {
        if (authUser) {
          authService.makeUserAuthenticated(authUser);
        } else {
          authService.makeUserUnauthenticated();
        }
      })
      .catch((error) => {
        authService.makeUserUnauthenticated();
      });
  };
}

export const AUTH_USER_INITIALIZER_PROVIDER: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: authUserInitializerFactory,
  deps: [Injector],
  multi: true
};
