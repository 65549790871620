import { Title } from '@angular/platform-browser';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { WINDOW } from '@app/_shared/tokens';

@Component({
  selector: 'sc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroyNotifier = new Subject<void>();

  isExplorer: boolean = (() => {
    const ua = this.window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  })();

  constructor(
    @Inject(WINDOW) private window: Window,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    /**
     * Set browser tab title, when navigation has been completed
     * */
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) =>
          route.data.pipe(
            tap((event) => {
              this.titleService.setTitle(event['title']);
            })
          )
        ),
        takeUntil(this.destroyNotifier)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyNotifier.next();
    this.destroyNotifier.complete();
  }
}
